import React, { Component } from "react";
import {
  Header,
  Button,
  Segment,
  Modal,
  Form,
  Icon,
  Grid,
  Table,
  Statistic,
  Menu,
  Popup,
  Input,
  Label,
  Divider,
} from "semantic-ui-react";
import Geosuggest from "react-geosuggest";

import api from "../../../actions/api";
import { Link } from "react-router-dom";
import { DateInput } from "semantic-ui-calendar-react";
import Moment from "react-moment";
import moment from "moment";
export default class PropertyPage extends Component {
  state = {
    addSys: false,
    edit: false,
    addLoading: false,
    property: {
      Systems: [],
    },
    settings: {
      SystemTypes: [],
      EquipmentType: [],
      MonitoringType: [],
      ServiceFrequency: [],
      ContractType: [],
    },
    data: {
      type: "",
      serialNum: "",
      date_installed: "",
      serviceInterval: 0,
      monitored: false,
      location: "",
      nextService: "",
    },
    propData: {},
    errors: {},
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.properties.show(this.props.match.params.pid).then((property) => {
      this.setState({
        property,
        propData: property,
      });
    });
    api.settings.lists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  handleDropdownChange = (props, e) => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value },
    });
  };
  goto = (id) => {
    this.props.history.push(`/systems/${id}`);
  };
  validate = (data) => {
    const errors = {};
    // if (!data.nextService) errors.nextService = "Can't be empty";
    if (!data.serviceInterval) errors.serviceInterval = "Can't be empty";
    if (!data.type) errors.type = "Can't be empty";
    if (!data.equipmentType) errors.equipmentType = "Can't be empty";
    if (!data.date_installed) errors.date_installed = "Can't be empty";
    if (!data.serialNum) errors.serialNum = "Can't be empty";

    return errors;
  };
  onAddSystem = () => {
    this.setState({
      addLoading: true,
    });
    let data = this.state.data;
    data.property = this.props.match.params.pid;
    data.client = this.props.match.params.id;
    data.extraData = this.state.xtra;
    // data.date_installed = data.date_installed + " 00:00:00";
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      api.systems.add(data).then(() => {
        this.getData();
        this.setState({
          addSys: false,
          addLoading: false,
          data: {
            type: "",
            serialNum: "",
            date_installed: "",
            serviceInterval: 0,
            monitored: false,
            location: "",
            nextService: "",
          },
        });
      });
    }
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  //Propety Section
  onPropertyChange = (e) =>
    this.setState({
      propData: { ...this.state.propData, [e.target.name]: e.target.value },
    });
  onSuggestSelect = (suggest) => {
    let coords = [];
    let components = {};
    if (suggest) {
      coords = [suggest.location.lng, suggest.location.lat];
      components = suggest.gmaps.address_components;
    }
    if (suggest) {
      this.setState({
        propData: {
          ...this.state.propData,
          address: suggest.gmaps.formatted_address,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: this.state.propData.name,
              address: suggest.gmaps.formatted_address,
            },
          },
        },
        errors: {},
        val: suggest,
      });
    }
  };
  updateproperty = () => {
    let data = this.state.propData;
    api.properties.edit(data._id, data).then(() => {
      this.getData();
      this.setState({
        edit: false,
      });
    });
  };
  //End Property Section
  renderPageHeader() {
    return (
      <Segment basic className="pageHeader">
        <Header as={"h1"}>Property: {this.state.property.name}</Header>
      </Segment>
    );
  }
  renderBasicDetails() {
    const { property } = this.state;
    return (
      <Grid celled className="no-marg">
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Name:
          </Grid.Column>
          <Grid.Column width={12}>{property.name}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Client:
          </Grid.Column>
          <Grid.Column width={12}>
            {property.client && (
              <Link to={`/clients/${property.client._id}`}>
                {property.client.name}
              </Link>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Address:
          </Grid.Column>
          <Grid.Column width={12}>{property.address}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderSystems() {
    const systems = this.state.property.Systems.map((p) => {
      let alarm = false;
      let future = false;

      if (p.nextService) {
        if (moment(p.nextService).isBefore(moment())) {
          alarm = true;
        }
      }
      if (p.nextService) {
        if (moment(p.nextService).isAfter(moment())) {
          future = true;
        }
      }
      return (
        <Table.Row
          key={p._id}
          onClick={() => this.goto(p._id)}
          negative={alarm}
          positive={future}
        >
          <Table.Cell>{p.type}</Table.Cell>
          <Table.Cell>{p.equipmentType}</Table.Cell>
          <Table.Cell>
            <Moment format={"DD-MM-YYYY"}>{p.date_installed}</Moment>
          </Table.Cell>
          <Table.Cell>
            <Moment format={"DD-MM-YYYY"}>{p.nextService}</Moment>
          </Table.Cell>
          <Table.Cell>{p.monitored ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              <Header className="tableHeader">Systems</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <Button
                positive
                icon
                onClick={() => this.setState({ addSys: true })}
              >
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Equipment Type</Table.HeaderCell>
            <Table.HeaderCell>Date Installed/Taken Over</Table.HeaderCell>
            <Table.HeaderCell>Next Service</Table.HeaderCell>
            <Table.HeaderCell>Monitored</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{systems}</Table.Body>
      </Table>
    );
  }
  renderAddSystem() {
    const { errors } = this.state;
    return (
      <Modal
        open={this.state.addSys}
        onClose={() => this.setState({ addSys: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="video camera" content="Add System" />
        <Modal.Content>
          <Form>
            <Form.Group widths={4}>
              <Form.Field>
                <Form.Dropdown
                  name="type"
                  label="System Type"
                  value={this.state.data.type}
                  onChange={this.handleDropdownChange.bind(this)}
                  options={this.state.settings.SystemTypes}
                  selection
                  required={true}
                />
                {errors.type && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.type}
                  </p>
                )}
              </Form.Field>
              <Form.Field>
                <Form.Dropdown
                  name="equipmentType"
                  label="Equipment Type"
                  value={this.state.data.equipmentType}
                  onChange={this.handleDropdownChange.bind(this)}
                  options={this.state.settings.EquipmentType}
                  selection
                  required={true}
                />
                {errors.equipmentType && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.equipmentType}
                  </p>
                )}
              </Form.Field>

              <Form.Dropdown
                name="contractType"
                label="Contract Type"
                value={this.state.data.contractType}
                onChange={this.handleDropdownChange.bind(this)}
                options={this.state.settings.ContractType}
                selection
              />
              <Form.Field>
                <Form.Input
                  name="serialNum"
                  label="Serial Number *"
                  value={this.state.data.serialNum}
                  onChange={this.onChange}
                  placeholder=""
                  // required={true}
                />
                {errors.serialNum && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.serialNum}
                  </p>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field>
                <Form.Dropdown
                  name="serviceInterval"
                  label="Service Interval (months)"
                  value={this.state.data.serviceInterval}
                  onChange={this.handleDropdownChange.bind(this)}
                  options={this.state.settings.ServiceFrequency}
                  selection
                  required={true}
                />
                {errors.serviceInterval && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.serviceInterval}
                  </p>
                )}
              </Form.Field>
              <Form.Field>
                <DateInput
                  autoComplete={"off"}
                  animation={"off"}
                  name="date_installed"
                  label="Date Installed/Taken Over"
                  dateFormat="YYYY/MM/DD"
                  value={this.state.data.date_installed}
                  iconPosition="left"
                  onChange={this.handleDropdownChange}
                  startMode={"day"}
                />
                {errors.date_installed && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.date_installed}
                  </p>
                )}
              </Form.Field>
              <Form.Field>
                <DateInput
                  autoComplete={"off"}
                  animation={"off"}
                  name="nextService"
                  label={"Next Service"}
                  dateFormat="YYYY/MM/DD"
                  value={this.state.data.nextService}
                  iconPosition="left"
                  onChange={this.handleDropdownChange}
                  startMode={"day"}
                />
                {/* {errors.nextService && (
                  <p style={{ color: "red", fontSize: "0.8rem" }}>
                    {errors.nextService}
                  </p>
                )} */}
                {/* <Popup
                  trigger={<Icon name="info circle" />}
                  content={
                    "This will override the auto next service setting for a new system."
                  }
                /> */}
              </Form.Field>
            </Form.Group>
            <Form.TextArea
              name="location"
              label="Panel Location"
              value={this.state.data.location}
              onChange={this.onChange}
              placeholder=""
            />
            <Form.Group widths={4}>
              <Form.Dropdown
                name="monitored"
                label="Monitored?"
                value={this.state.data.monitored}
                onChange={this.handleDropdownChange.bind(this)}
                options={[
                  { key: 1, text: "No", value: false },
                  { key: 2, text: "Yes", value: true },
                ]}
                selection
              />
              {this.state.data.monitored && (
                <React.Fragment>
                  <Form.Dropdown
                    name="monitoringType"
                    label="Monitoring"
                    value={this.state.data.monitoringType}
                    onChange={this.handleDropdownChange.bind(this)}
                    options={this.state.settings.MonitoringType}
                    selection
                  />
                  <Form.Input
                    name="monitoredAccNum"
                    label="Monitored Account Number"
                    onChange={this.onSystemChange}
                    placeholder=""
                  />
                  <Form.Field width={4}>
                    <label>Monitoring Cost</label>
                    <Input
                      name="monitoring_cost"
                      labelPosition="right"
                      type="text"
                      onChange={this.onSystemChange}
                      placeholder="Amount"
                    >
                      <Label basic>&pound;</Label>
                      <input />
                      <Label>.00</Label>
                    </Input>
                  </Form.Field>
                </React.Fragment>
              )}
            </Form.Group>
            {this.state.data.monitored && (
              <Form.Group widths={3}>
                <Form.Dropdown
                  name="securecomm"
                  label="Securecomm?"
                  onChange={this.handleDropdownChange.bind(this)}
                  options={[
                    { key: 1, text: "No", value: false },
                    { key: 2, text: "Yes", value: true },
                  ]}
                  selection
                />
                {this.state.data.securecomm && (
                  <React.Fragment>
                    <Form.Input
                      name="securecommAccNum"
                      label="Securecomm Account Number"
                      onChange={this.onChange}
                      placeholder=""
                    />
                    <Form.Field>
                      <label>Securecomm Cost</label>
                      <Input
                        name="securecomm_cost"
                        labelPosition="right"
                        type="text"
                        onChange={this.onChange}
                        placeholder="Amount"
                      >
                        <Label basic>&pound;</Label>
                        <input />
                        <Label>.00</Label>
                      </Input>
                    </Form.Field>
                  </React.Fragment>
                )}
              </Form.Group>
            )}
            {this.state.data.monitored && (
              <Form.Group widths={3}>
                <Form.Dropdown
                  name="policed"
                  label="Policed"
                  onChange={this.handleDropdownChange.bind(this)}
                  options={[
                    { key: 1, text: "No", value: false },
                    { key: 2, text: "Yes", value: true },
                  ]}
                  selection
                />
                {this.state.data.policed && (
                  <React.Fragment>
                    <Form.Input
                      name="intruder_URN"
                      label="Intruder URN"
                      onChange={this.onChange}
                      placeholder=""
                    />
                    <Form.Input
                      name="pa_URN"
                      label="PA URN"
                      onChange={this.onChange}
                      placeholder=""
                    />
                  </React.Fragment>
                )}
              </Form.Group>
            )}
            <div className="formActions">
              <Form.Button
                negative
                onClick={() => this.setState({ addSys: false })}
                loading={this.state.addLoading}
              >
                Cancel
              </Form.Button>
              <Form.Button
                positive
                onClick={this.onAddSystem}
                loading={this.state.addLoading}
                disabled={this.state.addLoading}
              >
                Add
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderEditProperty() {
    const { propData } = this.state;
    return (
      <Modal
        open={this.state.edit}
        onClose={() => this.setState({ edit: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header icon="map" content="Edit Property" />
        <Modal.Content>
          <Form>
            <Form.Input
              name={"name"}
              onChange={this.onPropertyChange}
              value={propData.name}
              label="Name"
              required={true}
            />
            <p style={{ textAlign: "center", fontSize: "1.2rem" }}>Address</p>
            <Segment basic>
              <Grid columns={2}>
                <Grid.Column>
                  <label>Lookup</label>
                  <Geosuggest
                    ref={(el) => (this._geoSuggest = el)}
                    placeholder="123 the street, Molash, Kent"
                    inputClassName="geosuggest__input"
                    country={"gb"}
                    onSuggestSelect={this.onSuggestSelect}
                    suggestsClassName="geosuggest__suggests"
                    suggestsHiddenClassName="geosuggest__suggests--hidden"
                    suggestItemClassName="geosuggest__item"
                  />
                  <Divider hidden />
                  {propData.geoJSON && propData.geoJSON.properties && (
                    <Form.Field>
                      <label>Address</label>
                      <input
                        readOnly
                        value={propData.geoJSON.properties.address}
                      />
                    </Form.Field>
                  )}

                  <Form.Field>
                    <label>Coordinates</label>
                    {propData.geoJSON &&
                    propData.geoJSON.geometry &&
                    propData.geoJSON.geometry.coordinates.length ? (
                      <Icon name={"check"} color="green" />
                    ) : (
                      <Icon name={"close"} color="red" />
                    )}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <label>Free text</label>
                  <Form.TextArea
                    name={"address"}
                    value={propData.address}
                    onChange={this.onPropertyChange}
                  />
                </Grid.Column>
              </Grid>
              <Divider vertical>Or</Divider>
            </Segment>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ edit: false })}>
            Cancel
          </Button>
          <Button positive onClick={this.updateproperty}>
            <Icon name="check" />
            Update
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  render() {
    console.log(this.state);
    return (
      <Segment className="pageHolder">
        {this.renderAddSystem()}
        {this.renderPageHeader()}
        {this.renderEditProperty()}
        <Grid className="no-marg" stackable>
          <Grid.Row stretched>
            <Grid.Column width={8}>{this.renderBasicDetails()}</Grid.Column>
            <Grid.Column width={8}>
              <Menu widths={3}>
                <Popup
                  basic
                  header="Delete Property"
                  trigger={
                    <Menu.Item disabled>
                      <Icon name="warning sign" color="red" /> Delete
                    </Menu.Item>
                  }
                  content={
                    "This will destroy this record and associated system records. **To be completed**"
                  }
                />
                <Popup
                  basic
                  header="Archive Property"
                  trigger={
                    <Menu.Item disabled>
                      <Icon name="archive" color="orange" />
                      Archive
                    </Menu.Item>
                  }
                  content={
                    "This will archive the property and associated systems. **To be completed**"
                  }
                />
                <Menu.Item onClick={() => this.setState({ edit: true })}>
                  <Icon name="pencil" color="blue" /> Edit
                </Menu.Item>
              </Menu>
              <Segment className={"statsBox"}>
                <Statistic.Group widths={1} size="small">
                  <Statistic>
                    <Statistic.Value>???</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Something maybe
                    </Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {this.renderSystems()}
      </Segment>
    );
  }
}
