import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { AuthenticationWrapper } from "@liquidcomputing/liquid__reactjs-localauth";
import AppConfig from "./_appConfig";
import Logo from "./assets/logo.png";
import { Segment } from "semantic-ui-react";
import MainMenu from "./components/common/MainMenu";
import HomePage from "./components/HomePage";
import Clients from "./components/clients";
import Settings from "./components/settings";
import SystemPages from "./components/systems";
import ServicesPages from "./components/services";
import Footer from "./components/common/footer";
import TaskPages from "./components/tasks";
import ReportsPage from "./components/reports";

class App extends React.Component {
  renderRoutes() {
    return (
      <div className="container">
        <div className="main">
          <MainMenu match={this.props.match} history={this.props.history} />
          {/* <ToastContainer
          draggable={false}
          hideProgressBar={true}
          autoClose={3000}
        /> */}
          {/* <Segment basic className="wrapper"> */}
          <Switch>
            <Route path="/services" component={ServicesPages} />
            <Route path="/task" component={TaskPages} />
            <Route path="/systems" component={SystemPages} />
            <Route path="/clients" component={Clients} />
            <Route path="/settings" component={Settings} />
            <Route path="/reports" component={ReportsPage} />
            <Route exact path="/" component={HomePage} />
          </Switch>
        </div>
        <Footer match={this.props.match} history={this.props.history} />
        {/* </Segment> */}

      </div>
    );
  }

  render() {
    return (
      <AuthenticationWrapper
        logo={Logo}
        config={{
          applicationServer: AppConfig.api_server, // required during local development
          localPrefix: AppConfig.app_storage_prefix,
        }}
        dynamicScopes={
          {
            // "test.permission.car.edit": (user, context) => {
            //     // Some complex logic in here to check the scope is allowed in the provided context
            //     if(user.email === "web@liquid-computing.co.uk" && context.organisation === "ksar"){
            //         return true;
            //     }
            //     return false;
            // }
          }
        }
      >
        {/* <SemanticToastContainer position="top-right" /> */}
        {/* <FrontendUpdateChecker /> */}
        {this.renderRoutes()}
      </AuthenticationWrapper>
    );
  }
}

export default withRouter(App);
