import React, { Component } from "react";
import { Segment, Button, Icon, Header, Grid } from "semantic-ui-react";
import Moment from "react-moment";
import NotesList from "./lists/notesList";

export default class AService extends Component {
  addServNote = (data) => {
    console.log("addnote", data);
  };
  render() {
    console.log(this.props);
    const { data } = this.props;
    const { system } = data;
    return (
      <Grid stackable celled centered>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign="center">
              {system.equipmentType} {system.type} Service
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center">
            <Header>Planned Date</Header>
            <Moment format="DD-MM-YYYY">{data.date_planned}</Moment>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header>Booked Date</Header>
            {data.date_booked ? (
              <Moment format="DD-MM-YYYY HH:mm">{data.date_booked}</Moment>
            ) : (
              "--"
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="center">
            <Header>Serial Number</Header>
            {system.serialNum}
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Header>Serviced Date</Header>
            {data.date_serviced ? (
              <Moment format="DD-MM-YYYY">{data.date_serviced}</Moment>
            ) : (
              "--"
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="no-pad">
            <NotesList
              notes={data.notes ? data.notes : []}
              title={"Notes"}
              add={this.addServNote}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
