import React, { Component } from "react";
import {
  Segment,
  Header,
  Button,
  Icon,
  Dropdown,
  Grid,
  Statistic,
  Table,
  Modal,
  Form,
  Divider,
  Label,
  Menu,
  Popup,
} from "semantic-ui-react";
import api from "../../actions/api";
import Geosuggest from "react-geosuggest";
import moment from "moment";
export default class ClientPage extends Component {
  state = {
    updateClient: false,
    addProp: false,
    client: {
      Properties: [],
      Systems: [],
    },
    data: {
      name: "",
      address: "",
      client: this.props.match.params.id,
    },
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    return api.clients.show(this.props.match.params.id).then((client) => {
      this.setState({
        client,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onClientChange = (e) =>
    this.setState({
      client: { ...this.state.client, [e.target.name]: e.target.value },
    });
  onUpdateClient = () => {
    let data = this.state.client;
    api.clients.update(this.props.match.params.id, data).then(() => {
      this.getData();
      this.setState({
        updateClient: false,
      });
    });
  };
  addProperty = () => {
    api.properties.add(this.state.data).then(() => {
      this.setState({
        addProp: false,
        data: {
          name: "",
          address: "",
          client: this.props.match.params.id,
          geoJSON: {},
        },
      });
      this.getData();
    });
  };
  goto = (id) => {
    this.props.history.push(
      `/clients/${this.props.match.params.id}/property/${id}`
    );
  };
  onSuggestSelect = (suggest) => {
    let coords = [];
    let components = {};
    if (suggest) {
      coords = [suggest.location.lng, suggest.location.lat];
      components = suggest.gmaps.address_components;
    }
    if (suggest) {
      this.setState({
        data: {
          ...this.state.data,
          address: suggest.gmaps.formatted_address,
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: this.state.data.name,
              address: suggest.gmaps.formatted_address,
            },
          },
        },
        errors: {},
        val: suggest,
      });
    }
  };
  renderAddProperty() {
    return (
      <Modal
        open={this.state.addProp}
        onClose={() => this.setState({ addProp: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header icon="building" content="Add Property" />
        <Modal.Content>
          <Form onSubmit={this.addProperty}>
            <Form.Input
              name={"name"}
              onChange={this.onChange}
              label="Name"
              required={true}
            />
            <p style={{ textAlign: "center", fontSize: "1.2rem" }}>Address</p>
            <Segment basic>
              <Grid columns={2}>
                <Grid.Column>
                  <label>Lookup</label>
                  <Geosuggest
                    ref={(el) => (this._geoSuggest = el)}
                    placeholder="123 the street, Molash, Kent"
                    inputClassName="geosuggest__input"
                    country={"gb"}
                    onSuggestSelect={this.onSuggestSelect}
                    suggestsClassName="geosuggest__suggests"
                    suggestsHiddenClassName="geosuggest__suggests--hidden"
                    suggestItemClassName="geosuggest__item"
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>Free text</label>
                  <Form.TextArea
                    name={"address"}
                    value={this.state.data.address}
                    onChange={this.onChange}
                  />
                </Grid.Column>
              </Grid>
              <Divider vertical>Or</Divider>
            </Segment>

            <Divider />
            <Form.Group style={{ justifyContent: "flex-end" }}>
              <Form.Button
                negative
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ addProp: false });
                }}
              >
                cancel
              </Form.Button>
              <Form.Button positive type="submit">
                Add
              </Form.Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderBasicDetails() {
    const { client } = this.state;
    return (
      <Grid celled className="no-marg">
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Ref Num:
          </Grid.Column>
          <Grid.Column width={12}>{client.clientNum}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Email:
          </Grid.Column>
          <Grid.Column width={12}>{client.email}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} className={"gridHeader"}>
            Phone:
          </Grid.Column>
          <Grid.Column width={12}>{client.phone}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  renderPageHeader() {
    const { client } = this.state;
    return (
      <Segment basic className="pageHeader">
        <Header as={"h1"}>Client: {client.name}</Header>
      </Segment>
    );
  }
  renderProperties() {
    const properties = this.state.client.Properties.map((p) => {
      return (
        <Table.Row key={p._id} onClick={() => this.goto(p._id)}>
          <Table.Cell>{p.name}</Table.Cell>
          <Table.Cell>{p.address}</Table.Cell>
          <Table.Cell>{p.systems ? p.systems.length : 0}</Table.Cell>
          <Table.Cell>
            {p.systems
              ? p.systems.map((s) => {
                  let colour = "orange";
                  if (s.nextService) {
                    if (moment(s.nextService).isBefore(moment())) {
                      colour = "red";
                    }
                    console.log(s.nextService);
                    if (moment(s.nextService).isAfter(moment())) {
                      colour = "green";
                    }
                  }
                  return (
                    <Label key={s._id} color={colour}>
                      {s.type}
                    </Label>
                  );
                })
              : "--"}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={3}>
              <Header className="tableHeader">Properties</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <Button
                positive
                icon
                onClick={() => this.setState({ addProp: true })}
              >
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell># Systems</Table.HeaderCell>
            <Table.HeaderCell>Types of Systems</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{properties}</Table.Body>
      </Table>
    );
  }
  renderUpdateClient() {
    return (
      <Modal
        open={this.state.updateClient}
        onClose={() => this.setState({ updateClient: false })}
        centered={false}
        size="small"
        closeOnDimmerClick={true}
      >
        <Header icon="user" content="Update Client" />
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <Form.Input
                name="clientNum"
                required
                label="Client Number"
                value={
                  this.state.client.clientNum ? this.state.client.clientNum : ""
                }
                onChange={this.onClientChange}
                placeholder="1234"
              />
              <Form.Input
                name="name"
                required
                label="Client Name"
                value={this.state.client.name ? this.state.client.name : ""}
                onChange={this.onClientChange}
                placeholder="1234"
              />
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Input
                name="contact"
                label="Contact Name"
                value={
                  this.state.client.contact ? this.state.client.contact : ""
                }
                onChange={this.onClientChange}
                placeholder="1234"
              />
              <Form.Input
                name="phone"
                label="Client Phone "
                value={this.state.client.phone ? this.state.client.phone : ""}
                onChange={this.onClientChange}
                placeholder="1234"
              />
              <Form.Input
                name="email"
                label="Client Email"
                type="email"
                value={this.state.client.email ? this.state.client.email : ""}
                onChange={this.onClientChange}
                placeholder="1234"
              />
            </Form.Group>
            <div className="formActions">
              <Form.Button
                negative
                onClick={() => this.setState({ updateClient: false })}
              >
                Cancel
              </Form.Button>
              <Form.Button positive onClick={this.onUpdateClient}>
                Update
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    console.log(this.state);
    return (
      <Segment className="pageHolder">
        {this.renderAddProperty()}
        {this.renderUpdateClient()}
        {this.renderPageHeader()}
        <Grid className="no-marg" stackable>
          <Grid.Row stretched>
            <Grid.Column width={8}>{this.renderBasicDetails()}</Grid.Column>
            <Grid.Column width={8}>
              <Menu widths={3}>
                <Menu.Item
                  onClick={() => this.setState({ updateClient: true })}
                >
                  <Icon name="pencil" color="blue" /> Edit Client
                </Menu.Item>
                <Menu.Item onClick={() => this.setState({ addNote: true })}>
                  <Icon name="sticky note" color="green" /> Add Note
                </Menu.Item>

                <Dropdown item className="menuDropdown" text="Admin">
                  <Dropdown.Menu>
                    <Dropdown.Header>Admin Functions</Dropdown.Header>

                    <Popup
                      basic
                      trigger={
                        <Dropdown.Item>
                          <Icon name="warning sign" color="red" /> Delete
                        </Dropdown.Item>
                      }
                      header="Delete Client"
                      content={
                        "This will destroy this record and associated system records. **To be completed**"
                      }
                    />
                    <Popup
                      basic
                      trigger={
                        <Dropdown.Item>
                          <Icon name="archive" color="orange" />
                          Archive
                        </Dropdown.Item>
                      }
                      header="Archive Client"
                      content={
                        "This will archive the property and associated systems. **To be completed**"
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
              <Segment className={"statsBox"}>
                <Statistic.Group widths={2}>
                  <Statistic>
                    <Statistic.Value>
                      {this.state.client.Properties.length}
                    </Statistic.Value>
                    <Statistic.Label>
                      <Icon name="building" />
                      Properties
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {this.state.client.Systems.length}
                    </Statistic.Value>
                    <Statistic.Label>
                      <Icon name="video camera" /> Systems
                    </Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment basic>{this.renderProperties()}</Segment>
      </Segment>
    );
  }
}
