import React, { Component } from "react";
import BuildNumber from "../../_buildNumber";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import { Authentication } from "@liquidcomputing/liquid__reactjs-localauth";
import { withRouter, Link } from "react-router-dom";
// import { FaBuilding, FaHome } from "react-icons/fa";

class TopNav extends Component {
  state = {
    activeItem: "",
  };

  componentDidUpdate(prevProps, prevState) { }

  goto = (data) => {
    this.props.history.push(data);
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleLogout = () => {
    Authentication.logout();
    window.location.reload();
  };

  render() {
    const { activeItem } = this.state;
    let context = Authentication.getUserData();

    return (
      <div className={'footer'}>
        <Menu  >
          <Dropdown item icon="user" className="menuDropdown">
            <Dropdown.Menu>
              <Dropdown.Header>
                {context.firstName} {context.lastName}
              </Dropdown.Header>
              <Dropdown.Item onClick={() => this.handleLogout()}>
                <Icon name="sign out" />
              Sign Out
            </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item
            as={Link}
            to="/settings"
            name={"settings"}
            active={activeItem === "settings"}
            onClick={this.handleItemClick}
          >
            <Icon name="cogs" />
          Settings
        </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>build: # {BuildNumber} </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}
export default withRouter(TopNav);
