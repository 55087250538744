import React, { Component } from "react";
import { Header, Table } from "semantic-ui-react";
import moment from "moment";
import Moment from "react-moment";

export default class ServicesList extends Component {
  render() {
    const services = this.props.services.map((p) => {
      let alarm = false;
      let future = false;

      if (p.date_planned) {
        if (moment(p.date_planned).isBefore(moment().subtract(30, "days"))) {
          alarm = true;
        }
      }
      if (p.date_planned) {
        if (moment(p.date_planned).isAfter(moment())) {
          future = true;
        }
      }
      return (
        <Table.Row
          key={p._id}
          onClick={() => this.props.action(p.system._id)}
          negative={alarm}
          positive={future}
        >
          <Table.Cell width={2}>{p.system.type}</Table.Cell>
          <Table.Cell width={2}>{p.system.equipmentType}</Table.Cell>
          <Table.Cell collapsing>
            <Moment format={"DD-MM-YYYY"}>{p.date_planned}</Moment>
          </Table.Cell>
          <Table.Cell collapsing>
            {p.date_booked ? (
              <Moment format={"DD-MM-YYYY"}>{p.date_booked}</Moment>
            ) : (
              "--"
            )}
          </Table.Cell>
          <Table.Cell collapsing>
            {p.date_serviced ? (
              <Moment format={"DD-MM-YYYY"}>{p.date_serviced}</Moment>
            ) : (
              "--"
            )}
          </Table.Cell>
          <Table.Cell>{p.system.client.name}</Table.Cell>
          <Table.Cell>{p.status}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={7}>
              <Header className="tableHeader">
                Services{" "}
                {this.props.title !== undefined ? this.props.title : ""}
              </Header>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Brand</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Planned</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Booked</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Serviced</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{services}</Table.Body>
      </Table>
    );
  }
}
