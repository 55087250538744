import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Grid,
  Input,
  Pagination,
  Segment,
  Table,
  Select,
  Message,
} from "semantic-ui-react";

class userList extends Component {
  state = {
    column: null,
    direction: null,
    pageSize: 15,
    currentPage: 1,
    query: {
      search: "",
      show_archived: false,
    },
  };

  //  Handlers
  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending",
      });
      return;
    }
    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handlePageSizeChange = (props, e) => {
    this.setState({
      pageSize: e.value,
      currentPage: 1,
    });
  };

  handlePageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  handleSearchQuery = (e) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          search: e.target.value,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  handleArchiveQuery = (show_archived) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          show_archived,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  //  Renders
  renderItems() {
    const { column, direction } = this.state;
    let data = _.sortBy(this.props.data, column);
    if (direction === "descending") {
      data = data.reverse();
    }
    // Return the list in the page form
    data = data.slice(
      (this.state.currentPage - 1) * this.state.pageSize,
      this.state.currentPage * this.state.pageSize
    );

    const items = data.map((item) => {
      return (
        <Table.Row
          key={item._id}
          onClick={() => {
            this.props.showEditForm(item);
          }}
        >
          <Table.Cell>{item.firstName + " " + item.lastName}</Table.Cell>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell>
            {item.groups.map((i, index) => {
              return <p key={index}>{i}</p>;
            })}
          </Table.Cell>
          <Table.Cell>
            <Button content="Edit" icon="pencil" color="green" />
          </Table.Cell>
        </Table.Row>
      );
    });
    if (items.length === 0) {
      return (
        <Message info>
          <Message.Header>No Data</Message.Header>There are no users to display.
        </Message>
      );
    }
    return (
      <Table stackable selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "firstName" ? direction : null}
              onClick={this.handleSort("firstName")}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "email" ? direction : null}
              onClick={this.handleSort("email")}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "groups" ? direction : null}
              onClick={this.handleSort("groups")}
            >
              Groups
            </Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{items}</Table.Body>
      </Table>
    );
  }

  renderPaginationArea() {
    let totalRecords = this.props.data.length;
    let totalPages = Math.ceil(totalRecords / this.state.pageSize);
    return (
      <Segment
        basic
        className="no-pad "
        style={{ justifyContent: "flex-end", display: "flex" }}
      >
        {totalPages !== 0 && (
          <Pagination
            size="mini"
            activePage={this.state.currentPage}
            onPageChange={this.handlePageChange}
            totalPages={totalPages}
            style={{ marginRight: "1rem" }}
          />
        )}
        <Select
          name={"PageSize"}
          options={[
            { key: 15, value: 15, text: "15 records" },
            { key: 30, value: 30, text: "30 records" },
            { key: 50, value: 50, text: "50 records" },
          ]}
          value={this.state.pageSize}
          onChange={this.handlePageSizeChange}
        />
      </Segment>
    );
  }

  render() {
    return (
      <Segment basic>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5} textAlign="center">
              <Button
                onClick={this.props.showAddForm}
                color="green"
                icon="plus"
                content="Add"
                size="large"
                circular
              />
              {/* <Button.Group className="buttonToggle" size="large">
                <Button
                  positive={this.state.query.show_archived === true}
                  onClick={() => this.handleArchiveQuery(true)}
                  active={this.state.query.show_archived === true}
                >
                  Show
                </Button>
                <Button
                  primary={this.state.query.show_archived === false}
                  onClick={() => this.handleArchiveQuery(false)}
                >
                  Hide
                </Button>
              </Button.Group> */}
            </Grid.Column>
            <Grid.Column width={4}>
              <Input
                className="inputStyle"
                autoComplete="off"
                name="name"
                fluid
                placeholder="Search Via User's Name"
                icon="search"
                onChange={this.handleSearchQuery}
              />
            </Grid.Column>
            <Grid.Column width={7}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>{this.renderItems()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default userList;
