import React from "react";

import {
  List,
  Segment,
  Button,
  Input,
  Form,
  Item,
  Header,
  Divider,
  Icon,
  Confirm,
  Table,
} from "semantic-ui-react";

//TODO make this ordered
export default class ZonesList extends React.Component {
  state = {
    data: {
      zone: "",
      info: "",
    },
    formData: [],
    isEdit: false,
    delete: false,
    delItem: {},
  };
  componentDidMount() {
    if (this.props.defaultXtra) {
      this.setState({
        info: this.props.defaultXtra,
      });
    }
  }
  openDelete = () => this.setState({ delete: true });
  closeDelete = () => this.setState({ delete: false, delItem: {} });
  deleteCheck = (obj) => {
    this.setState({
      delItem: obj,
      delete: true,
    });
  };
  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleSubmit = () => {
    if (this.state.data._id) {
      let fd = this.props.data.map((item) => {
        if (item._id === this.state.data._id) {
          return {
            ...item,
            zone: this.state.data.zone,
            info: this.state.data.info,
          };
        }
        return item;
      });
      let field = this.props.field;
      const data = {};
      data[field] = fd;
      this.props.submit(data).then(() => {
        this.setState({
          isEdit: false,
          data: {
            zone: "",
            info: "",
          },
        });
      });
    } else {
      let fd = this.props.data;
      fd.push({
        zone: this.state.data.zone,
        info: this.state.data.info,
      });
      this.setState({
        isEdit: false,
        data: {
          zone: "",
          info: "",
        },
      });
      let field = this.props.field;
      const data = {};
      data[field] = fd;
      this.props.submit(data);
    }
  };

  handleDelete = () => {
    let obj = this.state.delItem;
    if (obj) {
      let newList = this.props.data.filter((item, i) => {
        return obj !== item;
      });
      this.setState({
        formData: newList,
        delete: false,
        delItem: {},
      });
      let field = this.props.field;
      const data = {};
      data[field] = newList;
      this.props.delete(data);
    }
  };
  render() {
    console.log(this.props);
    const form = (
      <Segment basic className="no-pad">
        <Confirm
          open={this.state.delete}
          header="Delete Item!"
          onCancel={this.closeDelete}
          onConfirm={this.handleDelete}
        />
        <Form onSubmit={this.handleSubmit}>
          <Form.Group>
            <Form.Field width={4}>
              <Input
                fluid
                name="zone"
                onChange={this.handleChange}
                value={this.state.data.zone}
                placeholder={this.props.placeholder}
              />
            </Form.Field>
            <Form.Field width={10}>
              <Input
                name="info"
                onChange={this.handleChange}
                value={this.state.data.info}
                placeholder={this.props.placeholderLabel}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Button
                icon
                positive={this.state.isEdit ? false : true}
                primary={this.state.isEdit ? true : false}
              >
                {this.state.isEdit ? (
                  <span>
                    <Icon name="check" /> Update Zone
                  </span>
                ) : (
                  <span>
                    {" "}
                    <Icon name="plus" /> Add Zone
                  </span>
                )}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
    let list;
    if (this.props.data) {
      list = this.props.data.map((item, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>{item.zone}</Table.Cell>
            <Table.Cell>{item.info}</Table.Cell>
            <Table.Cell collapsing>
              <Button
                circular
                icon="close"
                floated="right"
                size="tiny"
                color="red"
                onClick={() => this.deleteCheck(item)}
              />
              <Button
                circular
                icon="pencil"
                floated="right"
                size="tiny"
                color="blue"
                onClick={() => this.setState({ data: item, isEdit: true })}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Segment basic>
        <Header>{this.props.title}</Header>
        <Divider />
        {form}

        <Table stackable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Zone</Table.HeaderCell>
              <Table.HeaderCell width={10}>Info</Table.HeaderCell>
              <Table.HeaderCell collapsing></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Segment>
    );
  }
}
