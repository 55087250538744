import React, { Component } from "react";
import { Form, Segment, Icon } from "semantic-ui-react";
import API from "../../../actions/api";

export default class editUserForm extends Component {
  state = {
    permissionGroups: [],
    globalError: null,
    errorMessage: null,
    isSubmitting: false,
    errors: {},
    email_check: "",
    data: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      groups: [],
        _changePassword: false
    },
  };

  componentDidMount() {
    this.getData();
  }
  getData() {
    API.staff.permissions.groups().then((res) => {
      this.setState({
        permissionGroups: res,
        data: this.props.data,
        email_check: this.props.data.email,
      });
    });
  }
  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };
  validate = (data) => {
    const errors = {};
    if (!data.firstName) errors.firstName = "Can't Be Empty";
    if (!data.lastName) errors.lastName = "Can't Be Empty";
    if (!data.email) errors.email = "Can't Be Empty";
    // if (this.state.email_check !== data.email) errors.email_check = "Email Does Not Match";
    if(data._changePassword){
        if(data.password === undefined || data.password.length < 6){
            errors.password = "Password must be longer then 6 characters";
        }
    }
    if (data.groups.length === 0) errors.groups = "Can't Be Empty";
    return errors;
  };
  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };
  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };
  render() {
    return (
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="firstName"
              label="First Name"
              value={this.state.data.firstName}
              onChange={this.handleChange}
              placeholder="First Name"
              error={this.state.errors.firstName}
            />
            <Form.Input
              name="lastName"
              label="Last Name"
              value={this.state.data.lastName}
              onChange={this.handleChange}
              placeholder="Last Name"
              error={this.state.errors.lastName}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="email"
              label="Email"
              value={this.state.data.email}
              onChange={this.handleChange}
              placeholder="Email"
              error={this.state.errors.email}
            />
            {/*<Form.Input*/}
            {/*  name="email_check"*/}
            {/*  label="Email Check"*/}
            {/*  value={this.state.email_check}*/}
            {/*  onChange={(event) =>*/}
            {/*    this.setState({ email_check: event.target.value })*/}
            {/*  }*/}
            {/*  placeholder="Enter Email Again"*/}
            {/*  error={this.state.errors.email_check || this.state.errors.email}*/}
            {/*/>*/}
          </Form.Group>
          <Form.Group widths="equal">
            {this.state.permissionGroups.length && (
              <Form.Dropdown
                name="groups"
                label="Groups"
                value={this.state.data.groups}
                onChange={this.handleDropdownChange}
                placeholder="Please Select A Group"
                options={this.state.permissionGroups.map((item, index) => {
                  return {
                    key: index,
                    text: item.name,
                    value: item.slug,
                  };
                })}
                multiple
                selection
                clearable
                error={this.state.errors.groups}
              />
            )}
          </Form.Group>
            <Form.Field>
                <Form.Checkbox label={"Change Password?"} checked={this.state.data._changePassword} onChange={e => this.setState({
                    data: {
                        ...this.state.data,
                        _changePassword: !this.state.data._changePassword
                    }
                })} />
            </Form.Field>
            {this.state.data._changePassword && <Form.Field>
                <Form.Input
                    name="password"
                    label="New Password"
                    type="password"
                    value={this.state.data.password}
                    onChange={this.handleChange}
                    placeholder="Password"
                    error={this.state.errors.password}
                />
            </Form.Field>}
          <Form.Group widths="equal">
            <Form.Button
              color="red"
              floated="left"
              onClick={() => {
                this.props.close();
              }}
            >
              <Icon name="close" />
              Cancel
            </Form.Button>
            <Form.Button color="green" floated="right" onClick={this.onSubmit}>
              <Icon name="cloud upload" />
              Update
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}
