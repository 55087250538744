import React, { Component } from 'react'
import {
    Segment,
    Grid,
    Header,
    Button,
    Statistic,
    Icon,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../actions/api";
export default class ReportsDash extends Component {
    render() {
        return (
            <Segment className="pageHolder">
                <Grid className="no-marg">
                    <Grid.Row columns="4">
                        <Grid.Column>
                            <Segment textAlign="center" onClick={() => this.props.history.push(`/reports/callout`)} className="selectable">
                                <Header>Callout Report</Header>
                                <Icon name="chart bar" size="huge" color={'red'} />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment textAlign="center" onClick={() => this.props.history.push(`/reports/maintenance`)} className="selectable">
                                <Header>Preventive maintenance</Header>
                                <Icon name="chart bar" size="huge" />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}
