import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Grid,
  Input,
  Pagination,
  Segment,
  Table,
  Select,
  Message,
} from "semantic-ui-react";

export default class ClientList extends Component {
  state = {
    column: null,
    direction: null,
    pageSize: 15,
    currentPage: 1,
    query: {
      search: "",
      archived: true,
    },
  };

  /**
   * Handlers
   */
  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending",
      });
      return;
    }
    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  handlePageSizeChange = (props, e) => {
    this.setState({
      pageSize: e.value,
      currentPage: 1, // Reset to one even if we are a few pages in
    });
  };

  handlePageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  handleSearchQuery = (e) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          search: e.target.value,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  handleStatusQuery = (data) => {
    this.setState(
      {
        query: {
          ...this.state.query,
          archived: data,
        },
      },
      () => {
        this.props.onQuery(this.state.query);
      }
    );
  };

  /**
   * Renders
   */
  renderList() {
    const { column, direction } = this.state;
    let data = _.sortBy(this.props.clients, column);
    if (direction === "descending") {
      data = data.reverse();
    }
    // Return the list in the page form
    data = data.slice(
      (this.state.currentPage - 1) * this.state.pageSize,
      this.state.currentPage * this.state.pageSize
    );
    const clients = data.map((c) => {
      return (
        <Table.Row key={c._id} onClick={() => this.props.action(c._id)}>
          <Table.Cell>{c.clientNum}</Table.Cell>
          <Table.Cell>{c.name}</Table.Cell>
          <Table.Cell>{c.contact}</Table.Cell>
          <Table.Cell>{c.email}</Table.Cell>
          <Table.Cell>{c.phone}</Table.Cell>
        </Table.Row>
      );
    });
    if (clients.length === 0) {
      return (
        <Message info>
          <Message.Header>No Records</Message.Header>There are no records to
          display
        </Message>
      );
    }
    return (
      <Table stackable selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "name" ? direction : null}
              onClick={this.handleSort("name")}
            >
              Ref
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "ref" ? direction : null}
              onClick={this.handleSort("ref")}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "sic" ? direction : null}
              onClick={this.handleSort("sic")}
            >
              Contact
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "namedSurveyor" ? direction : null}
              onClick={this.handleSort("namedSurveyor")}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "actingFor" ? direction : null}
              onClick={this.handleSort("actingFor")}
            >
              Phone
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{clients}</Table.Body>
      </Table>
    );
  }

  renderPaginationArea() {
    let totalRecords = this.props.clients.length;
    let totalPages = Math.ceil(totalRecords / this.state.pageSize);
    return (
      <Segment
        basic
        className="no-pad "
        style={{ justifyContent: "flex-end", display: "flex" }}
      >
        {totalPages !== 0 && (
          <Pagination
            size="mini"
            activePage={this.state.currentPage}
            onPageChange={this.handlePageChange}
            totalPages={totalPages}
            style={{ marginRight: "1rem" }}
          />
        )}
        <Select
          name={"PageSize"}
          options={[
            { key: 15, value: 15, text: "15 records" },
            { key: 30, value: 30, text: "30 records" },
            { key: 50, value: 50, text: "50 records" },
          ]}
          value={this.state.pageSize}
          onChange={this.handlePageSizeChange}
        />
      </Segment>
    );
  }

  render() {
    // console.log("**", this.state.query.archived);
    return (
      <Segment basic loading={this.props.loading}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5} textAlign="center">
              <Segment basic className="no-pad ">
                <Button.Group className="buttonToggle" size="large">
                  <Button
                    active={this.state.query.archived}
                    onClick={() => this.handleStatusQuery(true)}
                  >
                    Active
                  </Button>
                  <Button
                    active={!this.state.query.archived}
                    onClick={() => this.handleStatusQuery(false)}
                  >
                    Closed
                  </Button>
                </Button.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column width={4}>
              <Segment basic className="no-pad ">
                <Input
                  className="inputStyle"
                  autoComplete="off"
                  name="name"
                  fluid
                  placeholder="Please Enter A Name"
                  icon="search"
                  onChange={this.handleSearchQuery}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={7}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>{this.renderList()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>{this.renderPaginationArea()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
