import React, { Component } from "react";
import { Segment, Button, Icon, Header } from "semantic-ui-react";
import TasksList from "../common/lists/taskList";
import api from "../../actions/api";
export default class TaskList extends Component {
  state = {
    filter1: "New",
    filter2: "Today",
    tasks: [],
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const { filter1, filter2 } = this.state;
    api.tasks.filter(filter1, filter2).then((tasks) => {
      this.setState({
        tasks,
      });
    });
  };
  filter1 = (filter) => {
    this.setState({ filter1: filter }, () => {
      this.getData();
    });
  };
  filter2 = (filter) => {
    this.setState({ filter2: filter }, () => {
      this.getData();
    });
  };
  goto = (id) => {
    this.props.history.push(`/systems/${id}`);
  };
  renderPageHeader() {
    return (
      <Segment basic className="pageHeader">
        <Header as={"h1"}>Task List</Header>
        <Button.Group>
          <Button onClick={() => this.filter1("New")} active={this.state.filter1 === "New"}>
            <Icon name="calendar alternate" color={"orange"} /> New
          </Button>
          <Button onClick={() => this.filter1("Booked")} active={this.state.filter1 === "Booked"}>
            <Icon name="calendar check" color={"blue"} /> Booked
          </Button>
          <Button onClick={() => this.filter1("Completed")} active={this.state.filter1 === "Completed"}>
            <Icon name="check" color={"green"} /> Completed
          </Button>
        </Button.Group>
      </Segment>
    );
  }
  render() {
    return (
      <Segment className="pageHolder">
        {this.renderPageHeader()}
        <Segment textAlign="center">
          <Button.Group widths={7}>
            <Button onClick={() => this.filter2("Today")} active={this.state.filter2 === "Today"}>
              Today
          </Button>
            <Button onClick={() => this.filter2("Tomorrow")} active={this.state.filter2 === "Tomorrow"}>
              Tomorrow
          </Button>
            <Button onClick={() => this.filter2("7Days")} active={this.state.filter2 === "7Days"}>
              Next 7 days
          </Button>
            <Button onClick={() => this.filter2("thisMonth")} active={this.state.filter2 === "thisMonth"}>
              This Month
          </Button>
            <Button onClick={() => this.filter2("Yesterday")} active={this.state.filter2 === "Yesterday"}>
              Yesterday
          </Button>
            <Button onClick={() => this.filter2("last7Days")} active={this.state.filter2 === "last7Days"}>
              Last 7 Days
          </Button>
            <Button onClick={() => this.filter2("lastMonth")} active={this.state.filter2 === "lastMonth"}>
              Last Month
          </Button>
          </Button.Group>
        </Segment>
        <TasksList tasks={this.state.tasks} title={this.state.filter1} action={this.goto} />
      </Segment>
    );
  }
}
