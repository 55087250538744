import { APIRequest } from "@liquidcomputing/liquid__reactjs-localauth";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  _getBuildNumber: () => {
    return APIRequest.get(server + "/_buildNumber");
  },
  staff: {
    all: () => APIRequest.get(server + "/users"),
    show: (id) => APIRequest.get(server + `/users/${id}`),
    add: (data) => APIRequest.post(server + "/users", data),
    editUser: (id, data) => APIRequest.patch(server + "/users/" + id, data),
    permissions: {
      groups: () => APIRequest.get(server + "/users/permissions/groups"),
    },
  },
  clients: {
    all: () => APIRequest.get(server + "/clients"),
    show: (id) => APIRequest.get(server + `/clients/${id}`),
    add: (data) => APIRequest.post(server + "/clients", data),
    update: (id, data) => APIRequest.patch(server + "/clients/" + id, data),
  },
  services: {
    all: () => APIRequest.get(server + "/services"),
    filter: (filter1, filter2) =>
      APIRequest.get(
        server + "/services/filter?type=" + filter1 + "&range=" + filter2
      ),
    system: (id) => APIRequest.get(server + `/services/system/${id}`),
    show: (id) => APIRequest.get(server + `/services/${id}`),
    add: (id, data) => APIRequest.post(server + `/services/${id}`, data),
    update: (id, data) => APIRequest.patch(server + `/services/${id}`, data),
    addNote: (id, data) =>
      APIRequest.patch(server + `/services/${id}/note`, data),
  },

  systems: {
    all: () => APIRequest.get(server + "/systems"),
    show: (id) => APIRequest.get(server + `/systems/${id}`),
    delete: (id) => APIRequest.delete(server + `/systems/${id}`),
    client: (id) => APIRequest.get(server + `/systems/client/${id}`),
    property: (id) => APIRequest.get(server + `/systems/property/${id}`),
    add: (data) => APIRequest.post(server + "/systems", data),
    update: (id, data) => APIRequest.patch(server + "/systems/" + id, data),
    addNote: (id, data) =>
      APIRequest.patch(server + `/systems/${id}/note`, data),
    updateZones: (id, data) =>
      APIRequest.patch(server + `/systems/${id}/zones`, data),
  },
  tasks: {
    add: (data) => APIRequest.post(server + `/task`, data),
    system: (id) => APIRequest.get(server + `/task/system/${id}`),
    update: (id, data) => APIRequest.patch(server + `/task/${id}`, data),
    complete: (id, data) =>
      APIRequest.patch(server + `/task/${id}/complete`, data),
    addNote: (id, data) => APIRequest.patch(server + `/task/${id}/note`, data),
    filter: (filter1, filter2) =>
      APIRequest.get(
        server + "/task/filter?type=" + filter1 + "&range=" + filter2
      ),
  },
  properties: {
    all: () => APIRequest.get(server + "/properties/"),
    show: (id) => APIRequest.get(server + `/properties/${id}`),
    client: (id) => APIRequest.get(server + `/properties/client/${id}`),
    add: (data) => APIRequest.post(server + "/properties", data),
    edit: (id, data) => APIRequest.patch(server + "/properties/" + id, data),
  },
  settings: {
    all: () => APIRequest.get(server + `/settings/system`),
    lists: () => APIRequest.get(server + `/settings/system/formlists`),
    update: (data) => APIRequest.patch(server + `/settings/system`, data),
  },
  reports: {
    callout: (type, month, year = "2020") =>
      APIRequest.get(
        server + `/reports/callout?type=${type}&month=${month}&year=${year}`
      ),
    maint: (type) => APIRequest.get(server + `/reports/maint?type=${type}`),
    lists: () => APIRequest.get(server + `/settings/system/formlists`),
    update: (data) => APIRequest.patch(server + `/settings/system`, data),
    printCallout: (type, month, year = "2020") =>
      APIRequest.get(
        server +
          `/reports/callout/print?type=${type}&month=${month}&year=${year}`,
        {
          responseType: "blob",
        }
      ),
    printMaint: (type) =>
      APIRequest.get(server + `/reports/maint/print?type=${type}`, {
        responseType: "blob",
      }),
  },
};
