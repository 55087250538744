import React, { Component } from "react";
import { Segment, Header, Grid } from "semantic-ui-react";
import SettingsList from "../common/lists/settingList";
import api from "../../actions/api";

export default class Dash extends Component {
  state = {
    data: {
      matterStages: [],
    },
  };

  updateFormTimeout = 0;

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.settings.lists();
    return api.settings.all().then((data) => {
      this.setState({
        data,
      });
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (this.props.system !== prevProps.system) {
    //   this.setState({
    //     data: this.props.system.settings,
    //   });
    // }
    // if (this.props.system !== prevProps.system) {
    //   if (
    //     this.props.system.settings &&
    //     this.props.system.settings.incidentTypes
    //   ) {
    //     this.setOptions("type", this.props.system.settings.incidentTypes);
    //   }
    // }
  };

  onChange = (e) =>
    this.setState(
      {
        data: { ...this.state.data, [e.target.name]: e.target.value },
      },
      () => {
        this.updateSettings();
      }
    );

  updateSettings = () => {
    return api.settings
      .update(this.state.data)
      .then((res) => {
        this.setState({
          updating: false,
        });
        //TODO add a fancy screen notification that the title has successfully updated
      })
      .catch((err) => {
        console.error("Unable to save", err);
      });
  };

  updateModel = (e) => {
    this.setState({
      updating: true,
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
    if (this.updateFormTimeout) {
      clearTimeout(this.updateFormTimeout);
    }
    this.updateFormTimeout = setTimeout(() => {
      return api.settings
        .update(this.state.data)
        .then((res) => {
          this.setState({
            updating: false,
          });
          //TODO add a fancy screen notification that the title has successfully updated
        })
        .catch((err) => {
          console.error("Unable to save", err);
        });
    }, 500);
  };

  handleSubmit = (data) => {
    console.log(data);

    // return this.props.updateSetting(data).then(() => {
    //   this.getData();
    // });
    return api.settings
      .update(data)
      .then((res) => {
        this.setState({
          updating: false,
        });
        this.getData();
        //TODO add a fancy screen notification that the title has successfully updated
      })
      .catch((err) => {
        console.error("Unable to save", err);
      });
    // return this.updateSettings(data).then(() => {
    //   this.getData();
    // });
  };

  handleDelete = (data) => {
    console.log(data);
    return api.settings
      .update(data)
      .then((res) => {
        this.setState({
          updating: false,
        });
        this.getData();
        //TODO add a fancy screen notification that the title has successfully updated
      })
      .catch((err) => {
        console.error("Unable to save", err);
      });
    // this.props.updateSetting(data, () => {});
    // return this.updateSettings().then(() => {
    //   this.getData();
    // });
  };

  setOptions = (key, types) => {
    if (types) {
      const data = types;
      let dataArray = [];
      for (var i in data) {
        dataArray.push({
          key: i,
          text: data[i].name,
          value: data[i].name,
        });
      }
      this.setState({ [key]: dataArray });
    }
  };

  render() {
    console.log(this.state);

    return (
      <Segment>
        <Header>Settings Dash</Header>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.state.data.equipmentType}
                placeholder="HKC..."
                title="Equipment Type"
                field="equipmentType"
              />
            </Grid.Column>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.state.data.monitoringType}
                placeholder="None..."
                title="Monitoring Type"
                field="monitoringType"
              />
            </Grid.Column>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.state.data.contractType}
                placeholder="None..."
                title="Contract Type"
                field="contractType"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.state.data.serviceFrequency}
                placeholder="12..."
                title="Service Frequency (months)"
                field="serviceFrequency"
                type="number"
              />
            </Grid.Column>
            <Grid.Column>
              <SettingsList
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={this.state.data.systemType}
                placeholder="None..."
                title="System Type"
                field="systemType"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
