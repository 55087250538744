import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
export default class UpdateSettings extends Component {
  render() {
    return (
      <Segment>
        <Header>UpdateSettings</Header>
      </Segment>
    );
  }
}
