import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import TaskList from "./tasksList";

export default class TaskPages extends Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path={`/systems/list`} component={ClientList} />*/}
        {/* <Route path={`/clients/:id/property/:pid`} component={PropertyPage} /> */}
        <Route exact path={`/task/list`} component={TaskList} />
        {/* <Route path={`/services/:id`} component={} /> */}
      </Switch>
    );
  }
}
