import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Table,
  Button,
  Modal,
  Loader,
  Icon,
  Message,
  Select,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../actions/api";
import fileDownloader from "js-file-download";

export default class PreventetiveMaintanceReport extends Component {
  state = {
    data: [],
    type: "CCTV",
    settings: {
      SystemTypes: [],
    },
    loading: true,
    printing: false,
  };

  componentDidMount() {
    this.getData(this.state.type);
    api.settings.lists().then((settings) => {
      this.setState({
        settings,
      });
    });
  }

  getData = (type) => {
    api.reports.maint(type).then((res) => {
      this.setState({
        data: res.data,
        from: res.from,
        servTot: res.totS,
        servOT: res.totOT,
        to: res.to,
        servNA: res.totNA,
        loading: false,
      });
    });
  };
  setType = (type) => {
    this.setState(
      {
        type,
        loading: true,
      },
      () => {
        this.getData(this.state.type);
      }
    );
  };
  handleDropdownChange = (props, e) => {
    this.setState(
      {
        [e.name]: e.value,
      },
      () => {
        this.getData(this.state.type);
      }
    );
  };
  printReport = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    api.reports
      .printMaint(this.state.type)
      .then((theData) => {
        this.setState({
          printing: false,
        });
        console.log(theData);
        // Return the below as HTML for debugging
        fileDownloader(theData, "maint_report.pdf");
        // fileDownloader(theData, "task.html");
      })
      .catch((err) => {
        console.error(err);
      });
  };


  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button
                color={"red"}
                basic
                onClick={() => this.setState({ printing: false })}
              >
                Close
              </Button>
            </Modal.Description>
          ) : (
              <Modal.Description>
                <p>Please wait while we create the PDF...</p>
                <Loader />
              </Modal.Description>
            )}
        </Modal.Content>
      </Modal>
    );
  }

  renderData() {
    let totPercent =
      this.state.servTot > 0
        ? (this.state.servOT / this.state.servTot) * 100
        : 0;
    const data = this.state.data.map((d, i) => {
      let w = false, p = false, n = false;
      switch (true) {
        case d.percent > 95:
          p = true;
          break;
        case d.percent < 95 && d.percent > 90:
          w = true;
          break;
        default:
          n = true;
          break;
      }
      return (
        <Table.Row key={i} warning={w} positive={p} negative={n}>
          <Table.Cell>{d.month}</Table.Cell>
          <Table.Cell >{d.servicesTot}</Table.Cell>
          <Table.Cell >{d.onTime}</Table.Cell>
          <Table.Cell >{d.noAccess}</Table.Cell>
          <Table.Cell textAlign="center" >{d.percent}%</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Segment basic className="no-pad" loading={this.state.loading}>
        <Table striped celled textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Header as={"h4"}>
                  From: {moment(this.state.from).format("DD-MM-YYYY")} - To:{" "}
                  {moment(this.state.to).format("DD-MM-YYYY")}
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Header as={"h4"}>Total Services: {this.state.servTot}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Header as={"h4"}>
                  Total Services On Time: {this.state.servOT}
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Header as={"h4"}>
                  Total No Access: {this.state.servNA}
                </Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Header as={"h4"}>Total : {totPercent.toFixed(1)}%</Header>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Month</Table.HeaderCell>
              <Table.HeaderCell>Services</Table.HeaderCell>
              <Table.HeaderCell>On Time</Table.HeaderCell>
              <Table.HeaderCell>No Access</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{data}</Table.Body>
        </Table>
      </Segment>
    );
  }
  render() {
    // console.log(this.state);
    return (
      <Segment className="pageHolder">
        {this.renderPrintingModal()}
        <Segment basic className="pageHeader">
          <Header as={"h1"}>
            Preventive Maintenance Report <small>(rolling 12 months)</small>
          </Header>
        </Segment>
        <Segment
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="no-marg">Choose System Type</h3>
          <Select
            name={"type"}
            options={this.state.settings.SystemTypes}
            value={this.state.type}
            onChange={this.handleDropdownChange}
            style={{ marginRight: "1rem", marginLeft: "1rem" }}
          />
          <Button icon onClick={this.printReport}>
            <Icon name="print" /> Print
          </Button>
        </Segment>
        <Grid className="no-marg">
          <Grid.Row columns="1">
            <Grid.Column>{this.renderData()}</Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
