import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  Segment,
  Header,
  Button,
  Icon,
  Grid,
  Confirm,
  Menu,
  Table,
  Modal,
  Form,
  Popup,
  Divider,
  TextArea,
  Input,
  Label,
} from "semantic-ui-react";
import api from "../../actions/api";
import { DateTimeInput, DateInput } from "semantic-ui-calendar-react";

import moment from "moment";
import NotesList from "../common/lists/notesList";
import { Authentication } from "@liquidcomputing/liquid__reactjs-localauth";
import ZonesList from "../common/lists/zonesList";
import AService from "../common/aService";
import ATask from "../common/aTask";
export default class SystemPage extends Component {
  state = {
    activeItem: "services",
    addService: false,
    addServiceNote: false,
    serviceModal: false,
    updateService: false,
    editSystem: false,
    bookService: false,
    system: {
      securecommAccNum: "",
      monitoredAccNum: "",
      securecomm_cost: "",
      intruder_URN: "",
      pa_URN: "",
      serviceInterval: "",
      nextService: "",
    },
    settings: {},
    nextService: false,
    newService: {
      date_serviced: "",
      engineer: "",
      note: "",
      job: "",
      date_booked: "",
      schedule: true,
    },
    noteType: "",
    serviceID: "",
    serviceNote: {},
    service: {},
    task: {},
    services: [],
    tasks: [],
    newTask: {
      date_called: new moment().toISOString(),
      system: this.props.match.params.id,
      engineer: "",
      corrective: false,
      revisitReason: "",
    },
    updateTask: {
      date_completed: new moment().toString(),
      job: "",
      revisitReason: "",
      engineer: "",
    },
    addTask: false,
    bookTask: false,
    updateTaskModal: false,
    errors: {},
    data: {
      system: this.props.match.params.id,
    },
    delete: false,
  };
  componentDidMount() {
    this.getData();
  }
  openDelete = () => this.setState({ delete: true });
  closeDelete = () => this.setState({ delete: false });
  getData = () => {
    api.systems.show(this.props.match.params.id).then((system) => {
      this.setState({
        system: {
          ...this.state.system,
          ...system,
        },
      });
    });
    api.tasks.system(this.props.match.params.id).then((tasks) => {
      this.setState({
        tasks,
      });
    });
    api.services.system(this.props.match.params.id).then((services) => {
      this.setState({
        services,
      });
    });
    api.settings.lists().then((settings) => {
      this.setState({
        settings,
      });
    });
  };
  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
  onUpdateSystem = () => {
    let data = this.state.system;
    console.log(data);
    api.systems.update(this.props.match.params.id, data).then(() => {
      this.getData();
      this.setState({
        editSystem: false,
        newService: {
          date_serviced: "",
          engineer: "",
          note: "",
          job: "",
          date_booked: "",
        },
      });
    });
  };
  onChange = (e) =>
    this.setState({
      newService: { ...this.state.newService, [e.target.name]: e.target.value },
    });
  onSystemChange = (e) =>
    this.setState({
      system: { ...this.state.system, [e.target.name]: e.target.value },
    });
  handleSystemDropdownChange = (props, e) => {
    this.setState({
      system: { ...this.state.system, [e.name]: e.value },
    });
  };
  addNote = (note) => {
    let newNote = note;
    let notes = this.state.system.notes ? this.state.system.notes : [];
    let context = Authentication.getUserData();
    newNote.by = context.firstName + " " + context.lastName;
    newNote.date = new Date();
    notes.push(newNote);
    let data = {
      notes,
    };
    return api.systems.addNote(this.state.system._id, data).then(() => {
      this.getData();
    });
  };

  // Service Stuff
  addNewService = (data, nextService) => {
    console.log('add', data);
    this.setState({
      updateService: true,
      nextService: nextService,
      newService: {
        ...this.state.newService,
        status: "Completed",

        _id: data._id,
        noAccess: data.noAccess,
        date_booked: moment(data.date_booked).format("YYYY-MM-DD HH:mm"),
        date_serviced: moment(data.date_booked).format("YYYY-MM-DD HH:mm"),
      },
    });
  };
  editService = (data) => {
    console.log('edit', data);
    this.setState({
      updateService: true,
      nextService: false,
      newService: {
        // ...this.state.newService,
        status: "Completed",
        date_serviced: "",
        engineer: data.engineer ? data.engineer : '',
        note: data.note,
        job: data.job ? data.job : '',
        _id: data._id,
        noAccess: data.noAccess,
        date_booked: moment(data.date_booked).format("YYYY-MM-DD HH:mm"),
        date_serviced: moment(data.date_serviced).format("YYYY-MM-DD HH:mm"),
      },
    });
  };
  addService = () => {
    let data = this.state.data;
    api.services.add(this.props.match.params.id, data).then(() => {
      this.getData();
      this.setState({
        addService: false,
      });
    });
  };
  updateBooking = () => {
    let data = {
      date_booked: this.state.newService.date_booked,
      status: "Booked",
      note: this.state.newService.note,
    };
    console.log(data);
    api.services.update(this.state.newService._id, data).then(() => {
      this.getData();
      this.setState({
        bookService: false,
        newService: {
          date_serviced: "",
          engineer: "",
          note: "",
          job: "",
          date_booked: "",
        },
      });
    });
  };
  updateService = () => {
    let data = this.state.newService;
    console.log(data);
    api.services.update(data._id, data).then(() => {
      this.getData();
      this.setState({
        updateService: false,
        bookService: false,
        newService: {
          date_serviced: "",
          engineer: "",
          note: "",
          job: "",
          date_booked: "",
          schedule: true,
        },
      });
    });
  };
  onChange_serviceNote = (e) =>
    this.setState({
      serviceNote: {
        ...this.state.serviceNote,
        [e.target.name]: e.target.value,
      },
    });
  handleCheckboxChange = (props, e) => {
    this.setState({
      newService: { ...this.state.newService, [e.name]: e.checked },
    });
  };

  handleDropdownChange = (props, e) => {
    this.setState({
      newService: { ...this.state.newService, [e.name]: e.value },
    });
  };
  handleUpdateTaskDropdownChange = (props, e) => {
    this.setState({
      updateTask: { ...this.state.updateTask, [e.name]: e.value },
    });
  };
  onTaskUpdateChange = (e) =>
    this.setState({
      updateTask: { ...this.state.updateTask, [e.target.name]: e.target.value },
    });
  handleUpdateTaskCheckboxChange = (props, e) => {
    this.setState({
      updateTask: { ...this.state.updateTask, [e.name]: e.checked },
    });
  };

  handleBookingDateChange = (props, e) => {
    this.setState({
      newService: { ...this.state.newService, [e.name]: e.value },
    });
  };
  onBookService = (service) => {
    this.setState({
      bookService: true,
      newService: {
        ...this.state.newService,
        ...service,
      },
    });
  };
  openServiceNoteModal = (service) => {
    this.setState({
      serviceID: service._id,
      addServiceNote: true,
    });
  };
  openServiceModal = (service) => {
    this.setState({
      service: service,
      serviceModal: true,
    });
  };
  // addServiceNote = () => {
  //   let newNote = this.state.serviceNote;
  //   let context = Authentication.getUserData();
  //   newNote.by = context.firstName + " " + context.lastName;
  //   newNote.date = new Date();
  //   return api.services.addNote(this.state.serviceID, newNote).then(() => {
  //     this.setState({
  //       addServiceNote: false,
  //       serviceID: "",
  //     });
  //     this.getData();
  //   });
  //   console.log(newNote);
  // };
  openServiceNoteModal = (service, type) => {
    this.setState({
      serviceID: service._id,
      noteType: type,
      addServiceNote: true,
    });
  };
  openServiceModal = (service) => {
    this.setState({
      service: service,
      serviceModal: true,
    });
  };
  addServiceNote = () => {
    let newNote = this.state.serviceNote;
    let context = Authentication.getUserData();
    newNote.by = context.firstName + " " + context.lastName;
    newNote.date = new Date();
    if (this.state.noteType === "s") {
      return api.services.addNote(this.state.serviceID, newNote).then(() => {
        this.setState({
          addServiceNote: false,
          serviceID: "",
          noteType: "",
        });
        this.getData();
      });
    } else if (this.state.noteType === "t") {
      return api.tasks.addNote(this.state.serviceID, newNote).then(() => {
        this.setState({
          addServiceNote: false,
          serviceID: "",
          noteType: "",
        });
        this.getData();
      });
    }
  };
  //Task Stuff
  openTaskModal = (task) => {
    this.setState({
      task: task,
      taskModal: true,
    });
  };
  onBookTask = (task) => {
    this.setState({
      bookTask: true,
      newTask: {
        _id: task._id,
        date_booked: task.date_booked,
        engineer: task.engineer ? task.engineer : "",
        status: "Booked",
        corrective: false,
      },
    });
  };
  updateTask = () => {
    let data = this.state.newTask;
    console.log(data);
    api.tasks.update(data._id, data).then(() => {
      this.getData();
      this.setState({
        updateTaskModal: false,
        bookTask: false,
      });
    });
  };
  handlecompletedTask = () => {
    let data = this.state.updateTask;
    console.log("Complete", data);
    api.tasks.complete(data._id, data).then(() => {
      this.getData();
      this.setState({
        updateTaskModal: false,
        bookTask: false,
      });
    });
  };
  onTaskChange = (e) =>
    this.setState({
      newTask: { ...this.state.newTask, [e.target.name]: e.target.value },
    });
  handleTaskDropdownChange = (props, e) => {
    this.setState({
      newTask: { ...this.state.newTask, [e.name]: e.value },
    });
  };
  handleTaskCheckboxChange = (props, e) => {
    this.setState({
      newTask: { ...this.state.newTask, [e.name]: e.checked },
    });
  };
  addSystemTask = () => {
    let data = this.state.newTask;
    if (data.date_called) {
      data.date_called = data.date_called;
    }
    if (data.date_booked) {
      data.status = "Booked";
    }
    api.tasks.add(data).then(() => {
      this.setState({
        newTask: {
          date_called: new moment().toISOString(),
          system: this.props.match.params.id,
          engineer: "",
        },
        addTask: false,
      });
      this.getData();
    });
    // console.log(data);
  };
  completeTask = (t) => {
    console.log("task", t);
    this.setState({
      updateTask: {
        ...this.state.updateTask,
        ...t,
      },
      updateTaskModal: true,
    });
  };
  deleteSystem = () => {
    let client = this.state.system.client._id;
    let property = this.state.system.property._id;
    api.systems.delete(this.state.system._id).then(() => {
      this.props.history.push(`/clients/${client}/property/${property}`);
    });
  };
  handleSubmit = (data) => {
    return api.systems.updateZones(this.state.system._id, data).then(() => {
      this.getData();
    });
  };
  handleDelete = (data) => {
    return api.systems.updateZones(this.state.system._id, data).then(() => {
      this.getData();
    });
  };
  renderDetails() {
    const { system } = this.state;
    return (
      <Grid className="no-pad no-marg">
        <Grid.Row columns={3} stretched>
          <Grid.Column className="no-pad" width={6}>
            <Grid celled className="no-marg">
              <Grid.Row>
                <Grid.Column width={16} className={"gridHeader"} textAlign="center">
                  <Header>Details</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Client:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.client && (
                    <Link to={`/clients/${system.client._id}`}>
                      {system.client.name}
                    </Link>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Property:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.property && (
                    <Link
                      to={`/clients/${system.client._id}/property/${system.property._id}`}
                    >
                      {system.property.name}
                    </Link>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Contract Type:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.contractType}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={6} >
            <Grid celled className="no-marg"  >
              <Grid.Row>
                <Grid.Column width={16} className={"gridHeader"} textAlign="center">
                  <Header>System</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Brand:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.equipmentType}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Serial #:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.serialNum}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Maint Cost:
                </Grid.Column>
                <Grid.Column width={10}>
                  &pound;{system.maintenance_cost}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Location:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.location}
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Grid.Column>
          <Grid.Column className="no-pad" width={4}>
            <Grid celled className="no-marg">
              <Grid.Row>
                <Grid.Column width={16} className={"gridHeader"} textAlign="center">
                  <Header> Monitoring</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Acc. Num:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.monitoredAccNum}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  Cost:
                </Grid.Column>
                <Grid.Column width={10}>
                  &pound;{system.monitoring_cost}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} className={"gridHeader"}>
                  By:
                </Grid.Column>
                <Grid.Column width={10}>
                  {system.monitoringType}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

        </Grid.Row>
        {system.monitored && (
          <Grid.Row>
            <Grid.Column width={16} className="no-pad no-marg">
              <Grid celled className=" no-marg">
                <Grid.Row>
                  <Grid.Column width={8} textAlign="center" className={"gridHeader"}>
                    <Header>Securecomm</Header>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="center" className={"gridHeader"}>
                    <Header>Policed</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2} className={"gridHeader"}>
                    Num:
                    </Grid.Column>
                  <Grid.Column width={2}>
                    {system.securecommAccNum}
                  </Grid.Column>
                  <Grid.Column width={2} className={"gridHeader"}>
                    Cost:
                    </Grid.Column>
                  <Grid.Column width={2}>
                    &pound;{system.securecomm_cost}
                  </Grid.Column>
                  <Grid.Column width={2} className={"gridHeader"}>
                    Intruder URN:
                    </Grid.Column>
                  <Grid.Column width={2}>{system.intruder_URN}</Grid.Column>
                  <Grid.Column width={2} className={"gridHeader"}>
                    PA URN:
                    </Grid.Column>
                  <Grid.Column width={2}>{system.pa_URN}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    )
  }
  // The Renders
  renderBasicDetails() {
    const { system } = this.state;
    return (
      <Grid celled className="no-marg">
        <Grid.Row>
          <Grid.Column width={3} className={"gridHeader"}>
            Client:
          </Grid.Column>
          <Grid.Column width={5}>
            {system.client && (
              <Link to={`/clients/${system.client._id}`}>
                {system.client.name}
              </Link>
            )}
          </Grid.Column>
          <Grid.Column width={3} className={"gridHeader"}>
            Property:
          </Grid.Column>
          <Grid.Column width={5}>
            {system.property && (
              <Link
                to={`/clients/${system.client._id}/property/${system.property._id}`}
              >
                {system.property.name}
              </Link>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} className={"gridHeader"}>
            Brand:
          </Grid.Column>
          <Grid.Column width={5}>{system.equipmentType}</Grid.Column>
          <Grid.Column width={3} className={"gridHeader"}>
            Serial Num:
          </Grid.Column>
          <Grid.Column width={5}>{system.serialNum}</Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3} className={"gridHeader"}>
            Contract Type:
          </Grid.Column>
          <Grid.Column width={5}>{system.contractType}</Grid.Column>
          <Grid.Column width={3} className={"gridHeader"}>
            Maintenance Cost:
          </Grid.Column>
          <Grid.Column width={5}>&pound;{system.maintenance_cost}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} className={"gridHeader"}>
            Panel Info:
          </Grid.Column>
          <Grid.Column width={13}>{system.location}</Grid.Column>
        </Grid.Row>
        {system.monitored && (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header>Monitoring</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} className={"gridHeader"}>
                Acc. Num:
              </Grid.Column>
              <Grid.Column width={2}>{system.monitoredAccNum}</Grid.Column>
              <Grid.Column width={3} className={"gridHeader"}>
                Monitoring Cost:
              </Grid.Column>
              <Grid.Column width={2}>
                &pound;{system.monitoring_cost}
              </Grid.Column>
              <Grid.Column width={3} className={"gridHeader"}>
                Monitored By:
              </Grid.Column>
              <Grid.Column width={3}>{system.monitoringType}</Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}
        {system.monitored && (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column width={16} className="no-pad no-marg">
                <Grid celled className=" no-marg">
                  <Grid.Row>
                    <Grid.Column width={8} textAlign="center" >
                      <Header>Securecomm</Header>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="center">
                      <Header>Policed</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={2} className={"gridHeader"}>
                      Num:
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {system.securecommAccNum}
                    </Grid.Column>
                    <Grid.Column width={2} className={"gridHeader"}>
                      Cost:
                    </Grid.Column>
                    <Grid.Column width={2}>
                      &pound;{system.securecomm_cost}
                    </Grid.Column>
                    <Grid.Column width={2} className={"gridHeader"}>
                      Intruder URN:
                    </Grid.Column>
                    <Grid.Column width={2}>{system.intruder_URN}</Grid.Column>
                    <Grid.Column width={2} className={"gridHeader"}>
                      PA URN:
                    </Grid.Column>
                    <Grid.Column width={2}>{system.pa_URN}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}
      </Grid>
    );
  }

  renderPageHeader() {
    const { system } = this.state;
    return (
      <Segment basic className="pageHeader">
        <Confirm
          header={"Delete this System?"}
          content={
            "Are you sure, this will also delete all services related to this system?"
          }
          size="small"
          open={this.state.delete}
          onCancel={this.closeDelete}
          onConfirm={this.deleteSystem}
        />
        <Header as={"h1"}>System: {system.type}</Header>
        <Button.Group>
          <Button negative basic onClick={this.openDelete}>
            <Icon name="warning circle" /> Delete
          </Button>
          <Button
            basic
            primary
            onClick={() => this.setState({ editSystem: true })}
          >
            <Icon name="pencil" /> Edit
          </Button>
        </Button.Group>
      </Segment>
    );
  }
  renderMenu() {
    const { activeItem } = this.state;
    return (
      <Menu stackable>
        <Menu.Item
          name="services"
          active={activeItem === "services"}
          onClick={this.handleMenuClick}
        >
          Services
        </Menu.Item>
        <Menu.Item
          name="adhoc"
          active={activeItem === "adhoc"}
          onClick={this.handleMenuClick}
        >
          Ad-Hoc Tasks
        </Menu.Item>
        <Menu.Item
          name="zones"
          active={activeItem === "zones"}
          onClick={this.handleMenuClick}
        >
          Zones
        </Menu.Item>

        <Menu.Item
          name="notes"
          active={activeItem === "notes"}
          onClick={this.handleMenuClick}
        >
          Notes
        </Menu.Item>
      </Menu>
    );
  }
  renderZones() {
    return (
      <Segment basic>
        <ZonesList
          data={
            Array.isArray(this.state.system.zoneList)
              ? this.state.system.zoneList
              : []
          }
          submit={this.handleSubmit}
          delete={this.handleDelete}
          placeholder="Zone 1"
          placeholderLabel="info"
          title="Zones"
          field="zoneList"
        />
      </Segment>
    );
  }
  renderNotePopup(notes) {
    if (notes.length) {
      let theNotes = notes
        .map((n, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell collapsing>
                <Moment format="DD-MM-YYYY HH:mm">{n.date}</Moment>
              </Table.Cell>
              <Table.Cell>{n.message}</Table.Cell>
              <Table.Cell collapsing>{n.by}</Table.Cell>
            </Table.Row>
          );
        })
        .sort(function (a, b) {
          return a.date < b.date ? 1 : -1;
        });
      return (
        <Table className="no-marg no-pad">
          <Table.Body>{theNotes}</Table.Body>
        </Table>
      );
    } else {
      return "No notes";
    }
  }
  renderAddServiceNoteModal() {
    return (
      <Modal
        open={this.state.addServiceNote === true}
        onClose={() => this.setState({ addServiceNote: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Add A Note</Header>

        <Modal.Content>
          <Form onSubmit={this.addServiceNote}>
            <Form.Input
              name="message"
              label="Note"
              onChange={this.onChange_serviceNote}
              placeholder=""
              required={true}
            />
            <Divider />
            <Form.Group widths="2">
              <Form.Field>
                <Button
                  negative
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ addServiceNote: false });
                  }}
                >
                  Cancel
                </Button>
              </Form.Field>
              <Form.Field>
                <Button positive type="submit" floated="right">
                  Add
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderAddTaskModal() {
    const { errors } = this.state;
    return (
      <Modal
        open={this.state.addTask === true}
        onClose={() => this.setState({ addTask: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Add Task</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                name="date_called"
                label="Date Called"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={moment(this.state.newTask.date_called).format(
                  "YYYY-MM-DD HH:mm"
                )}
                iconPosition="left"
                onChange={this.handleTaskDropdownChange}
                startMode={"day"}
                closable
              />
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                minDate={this.state.newTask.date_called}
                name="date_booked"
                label="Date Booked"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={
                  this.state.newTask.date_booked
                    ? moment(this.state.newTask.date_booked).format(
                      "YYYY-MM-DD HH:mm"
                    )
                    : ""
                }
                iconPosition="left"
                onChange={this.handleTaskDropdownChange}
                startMode={"day"}
                closable
              />
            </Form.Group>

            <Form.Field>
              <label>Reason</label>
              <TextArea
                name="reason"
                onChange={this.onTaskChange}
                error={errors.reason}
                required
              />
            </Form.Field>

            <Form.Field>
              {/* <label>Corrective Task</label> */}
              <Form.Checkbox
                name="corrective"
                label="Corrective Task"
                checked={this.state.newTask.corrective}
                onChange={this.handleTaskCheckboxChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ addTask: false })}>
            Cancel
          </Button>
          <Button positive onClick={this.addSystemTask}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderServices() {
    const services = this.state.services
      .map((s) => {
        return (
          <Table.Row key={s._id}>
            <Table.Cell>
              <Moment format={"DD-MM-YYYY"}>{s.date_planned}</Moment>
            </Table.Cell>
            <Table.Cell collapsing>
              {s.date_booked ? (
                <Moment format={"DD-MM-YYYY HH:mm"}>{s.date_booked}</Moment>
              ) : (
                  "--"
                )}
            </Table.Cell>
            <Table.Cell>{s.status}</Table.Cell>
            <Table.Cell collapsing>
              {s.date_serviced ? (
                <Moment format={"DD-MM-YYYY HH:mm"}>{s.date_serviced}</Moment>
              ) : (
                  "--"
                )}
            </Table.Cell>
            <Table.Cell>{s.job}</Table.Cell>
            <Table.Cell>{s.engineer} &nbsp;
              {s.noAccess && <Label color="red">No Access</Label>}
            </Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {s.notes ? (
                <Popup
                  basic
                  flowing
                  size="large"
                  content={this.renderNotePopup(s.notes)}
                  trigger={<p>{s.notes.length}</p>}
                />
              ) : (
                  0
                )}
            </Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {s.status === "Completed" ? (
                <Button
                  icon
                  primary
                  size="tiny"
                  onClick={() => this.editService(s)}
                // onClick={() => this.addNewService(s._id, false)} Originally there cant remember why?
                >
                  <Icon name="pencil" />
                </Button>
              ) : (
                  <Popup
                    basic
                    size="large"
                    content={"Complete the service"}
                    trigger={
                      <Button
                        icon
                        positive
                        size="tiny"
                        onClick={() => this.addNewService(s, true)}
                      >
                        <Icon name="check" />
                      </Button>
                    }
                  />
                )}
              {s.status !== "Completed" && (
                <Popup
                  basic
                  trigger={
                    <Button
                      icon
                      size="tiny"
                      onClick={() => this.onBookService(s)}
                    >
                      <Icon name="calendar alternate" />
                    </Button>
                  }
                  content={"Book the service"}
                />
              )}
              <Popup
                basic
                size="large"
                content={"Add a note regarding service"}
                trigger={
                  <Button
                    icon
                    size="tiny"
                    onClick={() => this.openServiceNoteModal(s, "s")}
                  >
                    <Icon name="sticky note outline" />
                  </Button>
                }
              />
              <Popup
                basic
                size="large"
                content={"this will go to service maybe"}
                trigger={
                  <Button
                    icon
                    primary
                    size="tiny"
                    onClick={() => this.openServiceModal(s)}
                  >
                    <Icon name="binoculars" />
                  </Button>
                }
              />
            </Table.Cell>
          </Table.Row>
        );
      })
      .sort(function (a, b) {
        return a.date_planned < b.date_planned ? 1 : -1;
      });
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>
              <Header className="tableHeader">Services</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" colSpan={2}>
              <Button
                positive
                icon
                onClick={() => this.setState({ addService: true })}
              >
                <Icon name="plus" /> Schedule Next Service
              </Button>
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell collapsing>Planned Date</Table.HeaderCell>
            <Table.HeaderCell collapsing>Booked Date</Table.HeaderCell>
            <Table.HeaderCell collapsing>Status</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Serviced</Table.HeaderCell>
            <Table.HeaderCell collapsing>Job #</Table.HeaderCell>
            <Table.HeaderCell>Engineer</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{services}</Table.Body>
      </Table>
    );
  }
  renderAdhoc() {
    const tasks = this.state.tasks
      .map((s) => {
        return (
          <Table.Row key={s._id}>
            <Table.Cell collapsing>
              {s.date_called ? (
                <Moment format={"DD-MM-YYYY HH:mm"}>{s.date_called}</Moment>
              ) : (
                  "--"
                )}
            </Table.Cell>
            <Table.Cell collapsing>
              {s.date_booked ? (
                <Moment format={"DD-MM-YYYY HH:mm"}>{s.date_booked}</Moment>
              ) : (
                  "--"
                )}
            </Table.Cell>

            <Table.Cell collapsing>
              {s.date_completed ? (
                <Moment format={"DD-MM-YYYY HH:mm"}>{s.date_completed}</Moment>
              ) : (
                  "--"
                )}
            </Table.Cell>
            <Table.Cell>{s.status}</Table.Cell>
            {/* <Table.Cell>{s.job}</Table.Cell> */}
            <Table.Cell>{s.reason}</Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {s.corrective ? "yes" : "no"}
            </Table.Cell>
            <Table.Cell>{s.engineer}</Table.Cell>
            <Table.Cell collapsing textAlign="center">
              {s.notes ? (
                <Popup
                  basic
                  flowing
                  size="large"
                  content={this.renderNotePopup(s.notes)}
                  trigger={<p>{s.notes.length}</p>}
                />
              ) : (
                  0
                )}
            </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
              {s.status === "Completed" ? (
                <Button
                  icon
                  primary
                  size="tiny"
                  onClick={() => this.addNewService(s._id, false)}
                >
                  <Icon name="pencil" />
                </Button>
              ) : (
                  <Popup
                    basic
                    size="large"
                    content={"Complete the task"}
                    trigger={
                      <Button
                        icon
                        positive
                        size="tiny"
                        onClick={() => this.completeTask(s)}
                      >
                        <Icon name="check" />
                      </Button>
                    }
                  />
                )}
              {s.status !== "Completed" && (
                <Popup
                  basic
                  trigger={
                    <Button icon size="tiny" onClick={() => this.onBookTask(s)}>
                      <Icon name="calendar alternate" />
                    </Button>
                  }
                  content={"Book the service"}
                />
              )}
              <Popup
                basic
                size="large"
                content={"Add a note regarding task"}
                trigger={
                  <Button
                    icon
                    size="tiny"
                    onClick={() => this.openServiceNoteModal(s, "t")}
                  >
                    <Icon name="sticky note outline" />
                  </Button>
                }
              />
              <Popup
                basic
                size="large"
                content={"This will show task"}
                trigger={
                  <Button
                    icon
                    primary
                    size="tiny"
                    onClick={() => this.openTaskModal(s)}
                  >
                    <Icon name="binoculars" />
                  </Button>
                }
              />
            </Table.Cell>
          </Table.Row>
        );
      })
      .sort(function (a, b) {
        return a.date_planned < b.date_planned ? 1 : -1;
      });
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={7}>
              <Header className="tableHeader">Ad-Hoc Tasks</Header>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" colSpan={2}>
              <Button
                positive
                icon
                onClick={() => this.setState({ addTask: true })}
              >
                <Icon name="plus" /> Add Task
              </Button>
            </Table.HeaderCell>
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell collapsing>Date Called</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Booked</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Completed</Table.HeaderCell>
            <Table.HeaderCell collapsing>Status</Table.HeaderCell>
            {/* <Table.HeaderCell collapsing>Job #</Table.HeaderCell> */}
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>Corrective</Table.HeaderCell>
            <Table.HeaderCell collapsing>Engineer</Table.HeaderCell>
            <Table.HeaderCell collapsing>Notes</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tasks}</Table.Body>
      </Table>
    );
  }
  renderServiceModal() {
    return (
      <Modal
        open={this.state.serviceModal === true}
        onClose={() => this.setState({ serviceModal: false })}
        centered={false}
        closeOnDimmerClick={true}
        eventPool={"serviceModal"}
      >
        <Header className="modalHeader">Service</Header>
        <Modal.Content>
          <AService data={this.state.service} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ serviceModal: false })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderTaskModal() {
    return (
      <Modal
        open={this.state.taskModal === true}
        onClose={() => this.setState({ taskModal: false })}
        centered={false}
        closeOnDimmerClick={true}
        eventPool={"serviceModal"}
      >
        <Header className="modalHeader">Task</Header>
        <Modal.Content>
          <ATask data={this.state.task} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ taskModal: false })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  renderAddService() {
    return (
      <Modal
        open={this.state.addService === true}
        onClose={() => this.setState({ addService: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Add Service</Header>
        <Modal.Content>
          Ok what now
          <Button onClick={this.addService}>Add</Button>
        </Modal.Content>
      </Modal>
    );
  }
  renderBookService() {
    let planned = this.state.newService.date_planned;
    let booked = this.state.newService.date_booked;
    let diff = moment(planned).diff(moment(booked), "days");
    let days = "";

    if (!isNaN(diff) && diff > 0) {
      days = `Booked ${diff} days before planned date.`;
    } else if (diff < 0) {
      days = `Booked ${Math.abs(diff)} days after planned date.`;
    }

    return (
      <Modal
        open={this.state.bookService === true}
        onClose={() => this.setState({ bookService: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Book Service</Header>
        <Modal.Content>
          <Grid verticalAlign="middle">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header textAlign="center">
                  Service Planned for:{" "}
                  <Moment format={"DD-MM-YYYY"}>
                    {this.state.newService.date_planned}
                  </Moment>
                </Header>
              </Grid.Column>
              <Grid.Column>
                <Header textAlign="center">
                  Service Booked for:{" "}
                  {this.state.newService.date_booked && (
                    <Moment format={"DD-MM-YYYY"}>
                      {this.state.newService.date_booked}
                    </Moment>
                  )}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form>
                  <DateTimeInput
                    autoComplete={"off"}
                    animation={"off"}
                    name="date_booked"
                    label="Date Booked"
                    dateTimeFormat="YYYY/MM/DD HH:mm"
                    value={moment(this.state.newService.date_booked).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                    iconPosition="left"
                    onChange={this.handleDropdownChange}
                    startMode={"day"}
                    closable
                  />
                  <Form.Field>
                    <label>Note</label>
                    <input name="note" onChange={this.onChange} />
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Header textAlign="center">{days}</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
          <Button fluid onClick={this.updateBooking}>
            Add The Booking
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
  renderBookTask() {
    let called = this.state.newTask.date_called;
    let booked = this.state.newTask.date_booked;
    let diff = moment(called).diff(moment(booked), "days");
    let days = "";

    if (!isNaN(diff) && diff > 0) {
      days = `Booked ${diff} days before called date????.`;
    } else if (diff < 0) {
      days = ` ${Math.abs(diff)} days after called date.`;
    }

    return (
      <Modal
        open={this.state.bookTask === true}
        onClose={() => this.setState({ bookTask: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Book Task</Header>
        <Modal.Content>
          <Form>
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Header textAlign="center">
                    Date Called:{" "}
                    <Moment format={"DD-MM-YYYY"}>
                      {this.state.newTask.date_called}
                    </Moment>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header textAlign="center">
                    Task Booked for:{" "}
                    {this.state.newTask.date_booked && (
                      <Moment format={"DD-MM-YYYY"}>
                        {this.state.newTask.date_booked}
                      </Moment>
                    )}
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <Header textAlign="center">{days}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <DateTimeInput
                    autoComplete={"off"}
                    animation={"off"}
                    name="date_booked"
                    label="Date Booked"
                    dateTimeFormat="YYYY/MM/DD HH:mm"
                    value={moment(this.state.newTask.date_booked).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                    iconPosition="left"
                    onChange={this.handleTaskDropdownChange}
                    startMode={"day"}
                    closable
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Engineer</label>
                    <input
                      name="engineer"
                      value={this.state.newTask.engineer}
                      onChange={this.onTaskChange}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>Note</label>
                    <input name="note" onChange={this.onTaskChange} />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Divider />
          <Button fluid onClick={this.updateTask} positive>
            Book the Task
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
  renderEditSystem() {
    return (
      <Modal
        open={this.state.editSystem === true}
        onClose={() => this.setState({ editSystem: false })}
        centered={false}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header className="modalHeader">Edit System</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={4}>
              <Form.Dropdown
                name="type"
                label="System Type"
                value={this.state.system.type}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={this.state.settings.SystemTypes}
                selection
                required={true}
              />
              <Form.Dropdown
                name="equipmentType"
                label="Equipment Type"
                value={this.state.system.equipmentType}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={this.state.settings.EquipmentType}
                selection
                required={true}
              />
              <Form.Dropdown
                name="contractType"
                label="Contract Type"
                value={this.state.system.contractType}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={this.state.settings.ContractType}
                selection
              />
              <Form.Input
                name="serialNum"
                label="Serial Number"
                value={this.state.system.serialNum}
                onChange={this.onSystemChange}
                placeholder=""
                required={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Maintenance Cost</label>
                <Input
                  name="maintenance_cost"
                  value={this.state.system.maintenance_cost}
                  labelPosition="right"
                  type="text"
                  onChange={this.onSystemChange}
                  placeholder="Amount"
                >
                  <Label basic>&pound;</Label>
                  <input />
                  <Label>.00</Label>
                </Input>
              </Form.Field>
              <Form.Dropdown
                name="serviceInterval"
                label="Service Interval"
                value={this.state.system.serviceInterval.toString()}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={this.state.settings.ServiceFrequency}
                selection
              />
              <Form.Field>
                <DateInput
                  autoComplete={"off"}
                  animation={"off"}
                  name="nextService"
                  label={"Next Service"}
                  dateFormat="YYYY/MM/DD"
                  value={moment(this.state.system.nextService)
                    .format("YYYY-MM-DD")
                    .toString()}
                  iconPosition="left"
                  onChange={this.handleSystemDropdownChange.bind(this)}
                  startMode={"day"}
                />
              </Form.Field>
            </Form.Group>
            <Form.TextArea
              name="location"
              label="Panel Location"
              value={this.state.system.location}
              onChange={this.onSystemChange}
              placeholder=""
            />
            <Form.Group widths={4}>
              <Form.Dropdown
                name="monitored"
                label="Monitored?"
                value={this.state.system.monitored}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={[
                  { key: 1, text: "No", value: false },
                  { key: 2, text: "Yes", value: true },
                ]}
                selection
              />
              {this.state.system.monitored && (
                <React.Fragment>
                  <Form.Dropdown
                    name="monitoringType"
                    label="Monitoring"
                    value={this.state.system.monitoringType}
                    onChange={this.handleSystemDropdownChange.bind(this)}
                    options={this.state.settings.MonitoringType}
                    selection
                  />
                  <Form.Input
                    name="monitoredAccNum"
                    label="Monitored Account Number"
                    value={this.state.system.monitoredAccNum}
                    onChange={this.onSystemChange}
                    placeholder=""
                  />
                  <Form.Field width={4}>
                    <label>Monitoring Cost</label>
                    <Input
                      name="monitoring_cost"
                      value={this.state.system.monitoring_cost}
                      labelPosition="right"
                      type="text"
                      onChange={this.onSystemChange}
                      placeholder="Amount"
                    >
                      <Label basic>&pound;</Label>
                      <input />
                      <Label>.00</Label>
                    </Input>
                  </Form.Field>
                </React.Fragment>
              )}
            </Form.Group>
            {this.state.system.monitored && (
              <Form.Group widths={3}>
                <Form.Dropdown
                  name="securecomm"
                  label="Securecomm?"
                  value={this.state.system.securecomm}
                  onChange={this.handleSystemDropdownChange.bind(this)}
                  options={[
                    { key: 1, text: "No", value: false },
                    { key: 2, text: "Yes", value: true },
                  ]}
                  selection
                />
                {this.state.system.securecomm && (
                  <React.Fragment>
                    <Form.Input
                      name="securecommAccNum"
                      label="Securecomm Account Number"
                      value={this.state.system.securecommAccNum}
                      onChange={this.onSystemChange}
                      placeholder=""
                    />
                    <Form.Field>
                      <label>Securecomm Cost</label>
                      <Input
                        name="securecomm_cost"
                        value={this.state.system.securecomm_cost}
                        labelPosition="right"
                        type="text"
                        onChange={this.onSystemChange}
                        placeholder="Amount"
                      >
                        <Label basic>&pound;</Label>
                        <input />
                        <Label>.00</Label>
                      </Input>
                    </Form.Field>
                  </React.Fragment>
                )}
              </Form.Group>
            )}
            {this.state.system.monitored && (
              <Form.Group widths={3}>
                <Form.Dropdown
                  name="policed"
                  label="Policed"
                  value={this.state.system.policed}
                  onChange={this.handleSystemDropdownChange.bind(this)}
                  options={[
                    { key: 1, text: "No", value: false },
                    { key: 2, text: "Yes", value: true },
                  ]}
                  selection
                />
                {this.state.system.policed && (
                  <React.Fragment>
                    <Form.Input
                      name="intruder_URN"
                      label="Intruder URN"
                      value={this.state.system.intruder_URN}
                      onChange={this.onSystemChange}
                      placeholder=""
                    />
                    <Form.Input
                      name="pa_URN"
                      label="PA URN"
                      value={this.state.system.pa_URN}
                      onChange={this.onSystemChange}
                      placeholder=""
                    />
                  </React.Fragment>
                )}
              </Form.Group>
            )}
            <Divider />
            <Form.Group widths={3}>
              <Form.Dropdown
                name="archived"
                label="Archive System (*will need more logic)"
                value={this.state.system.archived}
                onChange={this.handleSystemDropdownChange.bind(this)}
                options={[
                  { key: 1, text: "No", value: false },
                  { key: 2, text: "Yes", value: true },
                ]}
                selection
              />
            </Form.Group>
            <div className="formActions">
              <Form.Button
                negative
                onClick={() => this.setState({ editSystem: false })}
              >
                Cancel
              </Form.Button>
              <Form.Button positive onClick={this.onUpdateSystem}>
                Update
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderNotes() {
    return (
      <NotesList
        notes={this.state.system.notes ? this.state.system.notes : []}
        title={"Notes"}
        add={this.addNote}
        allowAdd={true}
      />
    );
  }
  renderUpdateService() {
    return (
      <Modal
        open={this.state.updateService === true}
        onClose={() => this.setState({ updateService: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Update Service</Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={3}>
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                name="date_serviced"
                label="Date of Service"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={this.state.newService.date_serviced}
                iconPosition="left"
                onChange={this.handleDropdownChange}
                startMode={"day"}
              />
              <Form.Input
                name="engineer"
                label="Engineer"
                value={this.state.newService.engineer}
                onChange={this.onChange}
                placeholder=""
                required={true}
              />

              <Form.Input
                name="job"
                label="Jobber Job #"
                value={this.state.newService.job}
                onChange={this.onChange}
                placeholder=""
              />
            </Form.Group>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {this.state.nextService && (
                <Form.Checkbox
                  name="schedule"
                  label="Schedule Next Service"
                  checked={this.state.newService.schedule}
                  onChange={this.handleCheckboxChange}
                />
              )}
              <Form.Checkbox
                className="noAccess"
                name="noAccess"
                label="No Access"
                checked={this.state.newService.noAccess}
                onChange={this.handleCheckboxChange}
              />
            </div>
            <div className="formActions">
              <Form.Button
                negative
                onClick={() => this.setState({ updateService: false })}
              >
                Cancel
              </Form.Button>
              <Form.Button positive onClick={this.updateService}>
                Save
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  renderCompleteTask() {
    return (
      <Modal
        open={this.state.updateTaskModal === true}
        onClose={() => this.setState({ updateTaskModal: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Update Task</Header>
        <Modal.Content>
          {/* <Segment> */}
          <Grid celled className="no-marg">
            <Grid.Row>
              <Grid.Column width={4} className="gridHeader">
                Date Called
              </Grid.Column>
              <Grid.Column width={4}>
                <Moment format="DD-MM-YYYY HH:mm">
                  {this.state.updateTask.date_called}
                </Moment>
              </Grid.Column>
              <Grid.Column width={4} className="gridHeader">
                DateBooked
              </Grid.Column>
              <Grid.Column width={4}>
                <Moment format="DD-MM-YYYY HH:mm">
                  {this.state.updateTask.date_booked}
                </Moment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} className="gridHeader">
                Reason
              </Grid.Column>
              <Grid.Column width={4}>
                {this.state.updateTask.reason}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4} className="gridHeader">
                Corrective
              </Grid.Column>
              <Grid.Column width={4}>
                {this.state.updateTask.corrective ? (
                  <Icon name="check" />
                ) : (
                    <Icon name="close" />
                  )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Form>
            <Form.Group widths={3}>
              <DateTimeInput
                autoComplete={"off"}
                animation={"off"}
                name="date_completed"
                label="Date Completed"
                dateTimeFormat="YYYY/MM/DD HH:mm"
                value={moment(this.state.updateTask.date_completed).format(
                  "YYYY-MM-DD HH:mm"
                )}
                iconPosition="left"
                onChange={this.handleUpdateTaskDropdownChange}
                startMode={"day"}
              />
              <Form.Input
                name="engineer"
                label="Engineer"
                value={this.state.updateTask.engineer}
                onChange={this.onTaskUpdateChange}
                placeholder=""
                required={true}
              />

              <Form.Input
                name="job"
                label="Jobber Job #"
                value={this.state.updateTask.job}
                onChange={this.onTaskUpdateChange}
                placeholder=""
              />
            </Form.Group>

            <Form.Checkbox
              name="requireRevisit"
              label="Requires a revisit"
              checked={this.state.updateTask.requireRevisit}
              onChange={this.handleUpdateTaskCheckboxChange}
            />
            {this.state.updateTask.requireRevisit === true && (
              <Form.Input
                name="revisitReason"
                label="Revisit Reason"
                value={this.state.updateTask.revisitReason}
                onChange={this.onTaskUpdateChange}
                placeholder="Why do you need to revisit?"
              />
            )}
            <div className="formActions">
              <Form.Button
                negative
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ updateTaskModal: false });
                }}
              >
                Cancel
              </Form.Button>
              <Form.Button positive onClick={this.handlecompletedTask}>
                Complete Task
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    console.log(this.state);
    const { system, activeItem, services } = this.state;
    let nextService = "";
    let lastService = "";
    let nextC = "red";
    if (services.length) {
      let next = services.filter((s) => {
        return s.status !== "Completed";
      });
      let last = services
        .filter((s) => {
          return s.status === "Completed";
        })
        .sort(function (a, b) {
          return a.date_serviced < b.date_serviced ? 1 : -1;
        });

      nextService = next.length && next[0].date_planned;
      if (moment(nextService).isBefore(moment())) {
        nextC = "red";
      }
      if (moment(nextService).isAfter(moment())) {
        nextC = "green";
      }
      lastService = last.length && last[0].date_serviced;
      // console.log(nextService, lastService);
      //TODO Bug
      if (nextService === 0 && lastService !== 0) {
        nextService = moment(last[0].date_planned).add(
          system.serviceInterval,
          "months"
        );
        if (moment(nextService).isAfter(moment())) {
          nextC = "green";
        }
      }
      // console.log(nextService);
    } else {
      // console.log("bang");
      let di = system.date_installed;
      nextService = moment(di).add(system.serviceInterval, "months");
      if (moment(nextService).isAfter(moment())) {
        nextC = "green";
      }
    }

    return (
      <Segment className="pageHolder">
        {this.renderCompleteTask()}
        {this.renderUpdateService()}
        {this.renderAddService()}
        {this.renderBookService()}
        {this.renderEditSystem()}
        {this.renderPageHeader()}
        {this.renderAddServiceNoteModal()}
        {this.renderServiceModal()}
        {this.renderAddTaskModal()}
        {this.renderBookTask()}
        {this.renderTaskModal()}

        <Grid className="no-marg" stackable>
          <Grid.Row stretched className="no-pad">
            <Grid.Column width={16} className="no-pad">{this.renderDetails()}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column className="smallStat ">
              <Header>
                <Moment format={"DD-MM-YYYY"}>{system.date_installed}</Moment>
              </Header>
              <p>Installed</p>
            </Grid.Column>
            <Grid.Column className="smallStat">
              <Header>
                {lastService ? (
                  <Moment format={"DD-MM-YYYY"}>{lastService}</Moment>
                ) : (
                    "--"
                  )}
              </Header>
              <p> Last Service</p>
            </Grid.Column>
            <Grid.Column className="smallStat">
              <Header>
                {system.serviceInterval && system.serviceInterval + " months"}
              </Header>
              <p>Service Interval</p>
            </Grid.Column>
            <Grid.Column className="smallStat">
              <Header color={nextC}>
                {nextService ? (
                  <Moment format={"DD-MM-YYYY"}>{nextService}</Moment>
                ) : (
                    "--"
                  )}
              </Header>
              <p>Next Service</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {this.renderMenu()}

        <Segment className="no-pad">
          {activeItem === "services" && this.renderServices()}
          {activeItem === "zones" && this.renderZones()}
          {activeItem === "notes" && this.renderNotes()}
          {activeItem === "adhoc" && this.renderAdhoc()}
        </Segment>
      </Segment>
    );
  }
}
