import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Button,
  Table,
  Divider,
  Select,
  Form,
  Icon,
  Modal,
  Message,
  Loader,
} from "semantic-ui-react";
import moment from "moment";
import api from "../../actions/api";
import fileDownloader from "js-file-download";

const month = [
  { key: 1, text: "January", value: "January" },
  { key: 2, text: "February", value: "February" },
  { key: 3, text: "March", value: "March" },
  { key: 4, text: "April", value: "April" },
  { key: 5, text: "May", value: "May" },
  { key: 6, text: "June", value: "June" },
  { key: 7, text: "July", value: "July" },
  { key: 8, text: "August", value: "August" },
  { key: 9, text: "September", value: "September" },
  { key: 10, text: "October", value: "October" },
  { key: 11, text: "November", value: "November" },
  { key: 12, text: "December", value: "December" },
];
const year = [
  { key: 1, text: "2020", value: "2020" },
  { key: 2, text: "2021", value: "2021" },
  { key: 3, text: "2022", value: "2022" },
];
export default class CalloutReport extends Component {
  state = {
    data: {
      data: [],
      AVG: 0,
    },
    type: "CCTV",
    month: new moment().format("MMMM"),
    year: new moment().format("YYYY"),
    moment: new moment(),
    settings: {
      SystemTypes: [],
    },
    loading: true,
    printing: false,
  };

  componentDidMount() {
    this.getData(this.state.type, this.state.month);
    api.settings.lists().then((settings) => {
      this.setState({
        settings,
      });
    });
  }
  handleDropdownChange = (props, e) => {
    this.setState(
      {
        [e.name]: e.value,
      },
      () => {
        this.getData(this.state.type, this.state.month, this.state.year);
      }
    );
  };
  getData = (type, month, year) => {
    api.reports.callout(type, month, year).then((data) => {
      this.setState({
        data,
        loading: false,
      });
    });
  };
  printReport = () => {
    this.setState({
      printing: true,
      printingError: null,
    });
    api.reports
      .printCallout(this.state.type, this.state.month, this.state.year)
      .then((theData) => {
        this.setState({
          printing: false,
        });
        console.log(theData);
        // Return the below as HTML for debugging
        fileDownloader(theData, "callout_report.pdf");
        // fileDownloader(theData, "task.html");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setType = (type) => {
    this.setState(
      {
        type,
        loading: true,
      },
      () => {
        this.getData(this.state.type, this.state.month, this.state.year);
      }
    );
  };
  setMonth = (month) => {
    this.setState(
      {
        month,
        loading: true,
      },
      () => {
        this.getData(this.state.type, this.state.month, this.state.year);
      }
    );
  };
  setYear = (year) => {
    this.setState(
      {
        year,
        loading: true,
      },
      () => {
        this.getData(this.state.type, this.state.month, this.state.year);
      }
    );
  };
  renderTableBody() {
    return this.state.data.data.map((d) => {
      return (
        <Table.Row key={d._id}>
          <Table.Cell>{d.client}</Table.Cell>
          <Table.Cell>{d.job}</Table.Cell>
          <Table.Cell>
            {moment(d.date_called).format("DD-MM-YY HH:mm")}
          </Table.Cell>
          <Table.Cell>
            {moment(d.date_booked).format("DD-MM-YY HH:mm")}
          </Table.Cell>
          <Table.Cell textAlign="center">{d.Diffm}</Table.Cell>
          <Table.Cell textAlign="center">{d.Diffh}</Table.Cell>
        </Table.Row>
      );
    });
  }
  renderPrintingModal() {
    return (
      <Modal open={this.state.printing}>
        <Modal.Header>Creating PDF</Modal.Header>
        <Modal.Content>
          {this.state.printingError ? (
            <Modal.Description>
              <Message>
                <Message.Header>PDF creation error</Message.Header>
                {this.state.printingError}
              </Message>
              <Button
                color={"red"}
                basic
                onClick={() => this.setState({ printing: false })}
              >
                Close
              </Button>
            </Modal.Description>
          ) : (
              <Modal.Description>
                <p>Please wait while we create the PDF...</p>
                <Loader />
              </Modal.Description>
            )}
        </Modal.Content>
      </Modal>
    );
  }
  renderFilter() {
    const { SystemTypes } = this.state.settings;
    return (
      <Segment>
        <Form>
          <Form.Group widths={3}>
            <Form.Field>
              <label>Type</label>
              <Select
                name={"type"}
                options={this.state.settings.SystemTypes}
                value={this.state.type}
                onChange={this.handleDropdownChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Month</label>
              <Select
                name={"month"}
                options={month}
                value={this.state.month}
                onChange={this.handleDropdownChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Year</label>
              <Select
                name={"year"}
                options={year}
                value={this.state.year}
                onChange={this.handleDropdownChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <Divider />
        <Grid className="no-marg">
          <Grid.Row columns={4} verticalAlign={"middle"}>
            <Grid.Column textAlign="left">
              <Header>
                {this.state.type} in {this.state.month} {this.state.year}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="left">
              <Header>
                Total Tasks:{" "}
                {this.state.data.TotalTasks ? this.state.data.TotalTasks : 0}{" "}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="left">
              <Header>
                {" "}
                AVG mins: {this.state.data.AVG ? this.state.data.AVG : 0}{" "}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Button onClick={this.printReport}>
                <Icon name="print" />
                Print
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
  render() {
    // console.log(this.state);
    return (
      <Segment className="pageHolder">
        {this.renderPrintingModal()}
        <Segment basic className="pageHeader">
          <Header as={"h1"}>Callout Report</Header>
        </Segment>
        <Grid >
          <Grid.Row columns="1">
            <Grid.Column>
              {this.renderFilter()}
              <Segment
                textAlign="center"
                className="basic no-pad"
                loading={this.state.loading}
              >
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Job #</Table.HeaderCell>
                      <Table.HeaderCell>Date Called</Table.HeaderCell>
                      <Table.HeaderCell>Date Booked</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Diff (minutes)
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Diff (hours)
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{this.renderTableBody()}</Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment basic style={{ height: 30 }}></Segment>
      </Segment>
    );
  }
}
