import React, { Component } from "react";
import { Menu, Image } from "semantic-ui-react";
import { Authentication } from "@liquidcomputing/liquid__reactjs-localauth";
import { NavLink } from "react-router-dom";

export default class MainMenu extends Component {
  state = {
    activeItem: "dash",
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  handleLogout = () => {
    Authentication.logout();
    window.location.reload();
  };

  render() {
    const { activeItem } = this.state;
    return (
      <Menu stackable>
        <Menu.Item>
          <Image src={require("../../assets/logo.png")} size="small" />
        </Menu.Item>
        <Menu.Item name={"dash"} as={NavLink} exact to="/" active={activeItem === "dash"} onClick={this.handleItemClick}>
          Dash
        </Menu.Item>
        <Menu.Item name={"clients"} as={NavLink} to="/clients/list" active={activeItem === "clients"} onClick={this.handleItemClick}>
          Clients
        </Menu.Item>

        <Menu.Item name={"services"} as={NavLink} to="/services/list" exact active={activeItem === "services"} onClick={this.handleItemClick}>
          Services
        </Menu.Item>
        <Menu.Item name={"tasks"} as={NavLink} to="/task/list" exact active={activeItem === "tasks"} onClick={this.handleItemClick}>
          Tasks
        </Menu.Item>
        <Menu.Item name={"reports"} as={NavLink} to="/reports" exact active={activeItem === "reports"} onClick={this.handleItemClick}>
          Reports
        </Menu.Item>
      </Menu>
    );
  }
}
