import React, { Component } from "react";
import { Header, Segment, Modal } from "semantic-ui-react";
import api from "../../actions/api";
// import UserTable from "./userTable";
import UserTablePaginated from "../common/lists/userList";
import AddForm from "./forms/addUserForm";
import EditForm from "./forms/editUserForm";
import { toast } from "react-toastify";

export default class StaffManagement extends Component {
  state = {
    data: [],
    filtered: [],
    toggle_add: false,
    toggle_edit: false,
    edit_item: {},
    filter: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.staff
      .all()
      .then((res) => {
        this.setState({ data: res, filtered: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showAddForm = () => {
    this.setState({
      toggle_add: true,
    });
  };

  showEditForm = (item) => {
    this.setState({
      toggle_edit: true,
      edit_item: item,
    });
  };

  handleItemFilter = (query) => {
    // this.setState(
    //   {
    //     show_archived_status:
    //       query.show_archived !== undefined &&
    //       query.show_archived !== this.state.show_archived_status
    //         ? query.show_archived
    //         : this.state.show_archived_status,
    //   },
    //   () => {
    let updatedList = this.state.data;

    // if (this.state.show_archived_status === false) {
    //   updatedList = updatedList.filter((item) => {
    //     return item.archived === false;
    //   });
    // }

    updatedList = updatedList.filter((item) => {
      let fullName = item.firstName + " " + item.lastName;

      return fullName.toLowerCase().search(query.search.toLowerCase()) !== -1;
    });
    this.setState({ filtered: updatedList });
    //   }
    // );
  };

  renderToast() {
    if (this.state.toggle_add === true) {
      toast.success("User Added Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (this.state.toggle_edit === true) {
      toast.info("User Updated Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  renderAddModal() {
    const handleClose = () => {
      if (this.state.toggle_add === true) {
        this.setState({ toggle_add: false });
      }
    };

    const onSubmit = (data) => {
      return api.staff
        .add(data)
        .then(() => {
          handleClose();
          this.getData();
          this.renderToast();
        })
        .catch((err) => {
          this.setState({
            globalError:
              "Unable to retrieve data from the server at this time.",
          });
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_add}
        onClose={handleClose}
        size="small"
        basic
      >
        <Header icon="user" content="Add New User" />
        <Modal.Content>
          <AddForm submit={onSubmit} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderEditModal() {
    const handleClose = () => {
      if (this.state.toggle_edit === true) {
        this.setState({ toggle_edit: false });
      }
    };

    const onSubmit = (data) => {
      return api.staff
        .editUser(this.state.edit_item._id, data)
        .then(() => {
          handleClose();
          this.getData();
          this.renderToast();
        })
        .catch((err) => {
          this.setState({
            globalError:
              "Unable to retrieve data from the server at this time.",
          });
        });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.state.toggle_edit}
        onClose={handleClose}
        basic
        size="small"
      >
        <Header icon="user" content="Editing User" />
        <Modal.Content>
          <EditForm
            data={this.state.edit_item}
            submit={onSubmit}
            close={handleClose}
          />
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    return (
      <Segment>
        {this.renderAddModal()}
        {this.renderEditModal()}
        <Header>User Management</Header>
        <UserTablePaginated
          data={this.state.filtered}
          onQuery={this.handleItemFilter}
          showAddForm={this.showAddForm}
          showEditForm={this.showEditForm}
        />
      </Segment>
    );
  }
}
