import React, { Component } from "react";
import { Header, Table } from "semantic-ui-react";
import moment from "moment";
import Moment from "react-moment";

export default class TaskList extends Component {
  render() {
    const services = this.props.tasks.map((p) => {
      let alarm = false;
      let future = false;

      if (p.date_planned) {
        if (moment(p.date_planned).isBefore(moment().subtract(30, "days"))) {
          alarm = true;
        }
      }
      if (p.date_planned) {
        if (moment(p.date_planned).isAfter(moment())) {
          future = true;
        }
      }
      return (
        <Table.Row key={p._id} onClick={() => this.props.action(p.system._id)} negative={alarm} positive={future}>
          <Table.Cell collapsing>{p.system.client.name}</Table.Cell>
          <Table.Cell collapsing>{p.system.type}</Table.Cell>
          <Table.Cell>{p.reason}</Table.Cell>
          <Table.Cell collapsing>
            <Moment format={"DD-MM-YYYY"}>{p.date_called}</Moment>
          </Table.Cell>
          <Table.Cell collapsing>{p.date_booked ? <Moment format={"DD-MM-YYYY"}>{p.date_booked}</Moment> : "--"}</Table.Cell>
          <Table.Cell collapsing>{p.date_completed ? <Moment format={"DD-MM-YYYY"}>{p.date_completed}</Moment> : "--"}</Table.Cell>

          <Table.Cell collapsing>{p.status}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={7}>
              <Header className="tableHeader">Services {this.props.title !== undefined ? this.props.title : ""}</Header>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Called</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Booked</Table.HeaderCell>
            <Table.HeaderCell collapsing>Date Completed</Table.HeaderCell>

            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{services}</Table.Body>
      </Table>
    );
  }
}
