import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ClientList from "./ClientList";
import ClientPage from "./ClientPage";
import PropertyPage from "./properties";

export default class Clients extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`/clients/list`} component={ClientList} />
        <Route path={`/clients/:id/property/:pid`} component={PropertyPage} />
        <Route path={`/clients/:id`} component={ClientPage} />
      </Switch>
    );
  }
}
