import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Dash from "./dash";
import UpdateSettings from "./update";
import SettingsNav from "./settingsNav";
import StaffList from "./staffList";

export default class Settings extends Component {
  render() {
    return (
      <div>
        <SettingsNav match={this.props.match} history={this.props.history} />
        <Segment basic className="pageHolder">
          <Switch>
            <Route path={`/settings/update`} component={UpdateSettings} />
            <Route exact path={`/settings/staff`} component={StaffList} />
            <Route path={`/settings`} component={Dash} />
          </Switch>
        </Segment>
      </div>
    );
  }
}
