import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import SystemPage from "./System";

export default class SystemPages extends Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path={`/systems/list`} component={ClientList} />
          <Route path={`/clients/:id/property/:pid`} component={PropertyPage} /> */}
        <Route path={`/systems/:id`} component={SystemPage} />
      </Switch>
    );
  }
}
