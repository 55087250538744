import React, { Component } from "react";
import { Header, Button, Segment, Modal, Form, Icon } from "semantic-ui-react";
import api from "../../actions/api";
import ClientList from "../common/lists/clientList";
export default class ClientsList extends Component {
  state = {
    clients: [],
    filtered: [],
    addClient: false,
    data: {
      clientNum: "",
      name: "",
      contact: "",
      phone: "",
      email: "",
    },
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    return api.clients.all().then((clients) => {
      this.setState({
        clients,
        filtered: clients,
        loading: false,
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  onAddClient = () => {
    let data = this.state.data;
    console.log(data);
    return api.clients.add(data).then(() => {
      this.getData();
      this.setState({
        addClient: false,
      });
    });
  };
  handleClientFilter = (query) => {
    let updatedList = this.state.clients;
    if (query.archived !== true) {
      updatedList = updatedList.filter((client) => {
        return client.archived === true;
      });
    } else if (query.archived === true) {
      updatedList = updatedList.filter((client) => {
        return client.archived === false;
      });
    }
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().search(query.search.toLowerCase()) !== -1;
    });
    this.setState({ filtered: updatedList });
  };

  goTo = (id) => {
    this.props.history.push(`/clients/${id}`);
  };
  renderPageHeader() {
    return (
      <Segment basic className="pageHeader">
        <Header as={"h1"}>Client List</Header>
        <Button onClick={() => this.setState({ addClient: true })}>
          <Icon name="plus" color={"green"} /> Add
        </Button>
      </Segment>
    );
  }
  renderAddClient() {
    return (
      <Modal
        open={this.state.addClient === true}
        onClose={() => this.setState({ addClient: false })}
        centered={false}
        closeOnDimmerClick={false}
      >
        <Header>{this.state.addClient === true ? "Add" : "Edit"} Client</Header>
        <Modal.Content>
          <Form onSubmit={this.onAddClient}>
            <Form.Input
              name="name"
              label="Client Name"
              value={this.state.data.name ? this.state.data.name : ""}
              onChange={this.onChange}
              placeholder="1234"
              required={true}
            />

            <Form.Group widths={3}>
              <Form.Input
                name="contact"
                label="Contact Name"
                value={this.state.data.contact ? this.state.data.contact : ""}
                onChange={this.onChange}
                placeholder="1234"
              />
              <Form.Input
                name="phone"
                label="Client Phone "
                value={this.state.data.phone ? this.state.data.phone : ""}
                onChange={this.onChange}
                placeholder="1234"
                required={true}
              />
              <Form.Input
                name="email"
                label="Client Email"
                type="email"
                value={this.state.data.email ? this.state.data.email : ""}
                onChange={this.onChange}
                placeholder="1234"
              />
            </Form.Group>
            <div className="formActions">
              <Form.Button
                negative
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ addClient: false });
                }}
              >
                Cancel
              </Form.Button>
              <Form.Button positive type="submit">
                Add
              </Form.Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
  render() {
    return (
      <Segment className="pageHolder">
        {this.renderAddClient()}
        {this.renderPageHeader()}

        <ClientList
          loading={this.state.loading}
          clients={this.state.filtered}
          onQuery={this.handleClientFilter}
          action={this.goTo}
        />
      </Segment>
    );
  }
}
