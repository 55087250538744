import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import CalloutReport from "./callout";
import ReportsDash from "./dash";
import PreventetiveMaintanceReport from "./maintanance";

export default class ReportPages extends Component {
    render() {
        return (
            <Switch>

                <Route exact path={`/reports`} component={ReportsDash} />
                <Route exact path={`/reports/callout`} component={CalloutReport} />
                <Route exact path={`/reports/maintenance`} component={PreventetiveMaintanceReport} />
                {/* <Route path={`/services/:id`} component={} /> */}
            </Switch>
        );
    }
}