import React, { Component } from "react";
import {
  Segment,
  Grid,
  Header,
  Button,
  Statistic,
  Icon,
} from "semantic-ui-react";
import moment from "moment";
import api from "../actions/api";
import SevicesList from "./common/lists/servicesList";

export default class HomePage extends Component {
  state = {
    title: "",
    services: [],
    filtered: [],
    systems: [],
    dash: {
      noPlanned: 0,
      overdue: 0,
    },
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    api.services.all().then((services) => {
      this.setState(
        {
          services,
        },
        () => {
          this.checkServiceDates(this.state.services);
          // this.checkNextServiceDates(this.state.services);
        }
      );
    });
    api.systems.all().then((systems) => {
      this.setState(
        {
          systems,
        },
        () => {
          // this.checkNextServiceDates(this.state.systems);
        }
      );
    });
  };
  // checkNextServiceDates = (services) => {
  //   if (services) {
  //     let noPlanned = services.filter((s) => {
  //       return s.nextService === undefined;
  //     });
  //     let overdue = services.filter((s) => {
  //       return moment(s.nextService).isBefore(moment());
  //     });
  //     let next30 = services.filter((s) => {
  //       // console.log(s);
  //       return (
  //         moment(s.nextService).isBefore(moment().add(30, "days")) &&
  //         moment(s.nextService).isAfter(moment())
  //       );
  //     });

  //     let next60 = services.filter((s) => {
  //       return (
  //         moment(s.nextService).isBefore(moment().add(60, "days")) &&
  //         moment(s.nextService).isAfter(moment())
  //       );
  //     });
  //     this.setState({
  //       dash: {
  //         ...this.state.dash,
  //         noPlanned: noPlanned.length,
  //         overdue: overdue.length,
  //         next30: next30.length,
  //         next60: next60.length,
  //       },
  //     });
  //   }
  // };
  filterSystems = (f) => {
    if (f === "overdue") {
      this.setState({
        title: "with overdue services",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_planned).isBefore(moment()) &&
            (moment(s.date_booked).isBefore(moment()) ||
              s.date_booked === undefined) &&
            s.status !== "Completed"
          );
        }),
      });
    } else if (f === "noPlanned") {
      this.setState({
        title: "with no planned services",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_planned).isBefore(moment()) &&
            s.date_booked === undefined &&
            s.status !== "Completed"
          );
        }),
      });
    } else if (f === "30") {
      this.setState({
        title: "with planned services in next 30 days",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_planned).isBefore(moment().add(30, "days")) &&
            moment(s.date_planned).isAfter(moment()) &&
            s.status === "Planned"
          );
        }),
      });
    } else if (f === "60") {
      this.setState({
        title: "with planned services in next 60 days",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_planned).isBefore(moment().add(60, "days")) &&
            moment(s.date_planned).isAfter(moment()) &&
            s.status === "Planned"
          );
        }),
      });
    } else if (f === "30b") {
      this.setState({
        title: "with booked services in next 30 days",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_booked).isBefore(moment().add(30, "days")) &&
            moment(s.date_booked).isAfter(moment()) &&
            s.status === "Booked"
          );
        }),
      });
    } else if (f === "60b") {
      this.setState({
        title: "with booked services in next 60 days",
        filtered: this.state.services.filter((s) => {
          return (
            moment(s.date_booked).isBefore(moment().add(60, "days")) &&
            moment(s.date_booked).isAfter(moment()) &&
            s.status === "Booked"
          );
        }),
      });
    }
  };
  goto = (id) => {
    this.props.history.push(`/systems/${id}`);
  };
  checkServiceDates = (services) => {
    // console.log(services);
    if (services) {
      let next30 = services.filter((s) => {
        return (
          moment(s.date_planned).isBefore(moment().add(30, "days")) &&
          moment(s.date_planned).isAfter(moment()) &&
          s.status === "Planned"
        );
      });
      let next60 = services.filter((s) => {
        return (
          moment(s.date_planned).isBefore(moment().add(60, "days")) &&
          moment(s.date_planned).isAfter(moment()) &&
          s.status === "Planned"
        );
      });
      let next30B = services.filter((s) => {
        return (
          moment(s.date_booked).isBefore(moment().add(30, "days")) &&
          moment(s.date_booked).isAfter(moment()) &&
          s.status === "Booked"
        );
      });
      let next60B = services.filter((s) => {
        return (
          moment(s.date_booked).isBefore(moment().add(60, "days")) &&
          moment(s.date_booked).isAfter(moment()) &&
          s.status === "Booked"
        );
      });
      let overdue = services.filter((s) => {
        return (
          moment(s.date_planned).isBefore(moment()) &&
          (moment(s.date_booked).isBefore(moment()) ||
            s.date_booked === undefined) &&
          s.status !== "Completed"
        );
      });
      let noPlanned = services.filter((s) => {
        return (
          moment(s.date_planned).isBefore(moment()) &&
          s.date_booked === undefined &&
          s.status !== "Completed"
        );
      });
      // console.log(next30B);
      this.setState({
        dash: {
          ...this.state.dash,
          next30: next30.length,
          next60: next60.length,
          next30B: next30B.length,
          next60B: next60B.length,
          overdue: overdue.length,
          noPlanned: noPlanned.length,
        },
      });
    }
  };
  renderPageHeader() {
    return (
      <Segment basic className="pageHeader">
        <Header as={"h1"}>Dashboard</Header>
        {/* <Button onClick={() => this.setState({ editSystem: true })}>
          <Icon name="pencil" /> Edit
        </Button> */}
      </Segment>
    );
  }
  render() {
    // console.log(this.state);
    const { dash } = this.state;
    return (
      <Segment className="pageHolder">
        {this.renderPageHeader()}

        <Grid className="no-marg" stackable>
          <Grid.Row stretched columns={3}>
            <Grid.Column>
              <Segment className={"statsBox"}>
                <Header textAlign="center">Planned Services</Header>
                <Statistic.Group widths={2} size="small">
                  <Statistic
                    onClick={() => this.filterSystems("30")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.next30}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Next 30 Days
                    </Statistic.Label>
                  </Statistic>
                  <Statistic
                    onClick={() => this.filterSystems("60")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.next60}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Next 60 Days
                    </Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className={"statsBox"}>
                <Header textAlign="center">Booked Services</Header>
                <Statistic.Group widths={2} size="small">
                  <Statistic
                    onClick={() => this.filterSystems("30b")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.next30B}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Next 30 Days
                    </Statistic.Label>
                  </Statistic>
                  <Statistic
                    onClick={() => this.filterSystems("60b")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.next60B}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Next 60 Days
                    </Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className={"statsBox"}>
                <Header textAlign="center">Possible Service Issues</Header>
                <Statistic.Group widths={1} size="small">
                  <Statistic
                    onClick={() => this.filterSystems("overdue")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.overdue}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name="calendar" />
                      Overdue
                    </Statistic.Label>
                  </Statistic>
                  {/* <Statistic
                    onClick={() => this.filterSystems("noPlanned")}
                    className="selectable"
                  >
                    <Statistic.Value>{dash.noPlanned}</Statistic.Value>
                    <Statistic.Label>None Planned</Statistic.Label>
                  </Statistic> */}
                </Statistic.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <SevicesList
          services={this.state.filtered}
          title={this.state.title}
          action={this.goto}
        />
      </Segment>
    );
  }
}
