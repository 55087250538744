import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import ServiceList from "./servicesList";

export default class ServicesPages extends Component {
    render(){
        return (
            <Switch>
                {/* <Route exact path={`/systems/list`} component={ClientList} />*/}
                {/* <Route path={`/clients/:id/property/:pid`} component={PropertyPage} /> */}
                <Route exact path={`/services/list`} component={ServiceList}/>
                {/* <Route path={`/services/:id`} component={} /> */}
            </Switch>
        );
    }
}