import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Header } from "semantic-ui-react";

export default class SettingsNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
    };
  }

  handleItemClick = () => {};

  render() {
    const { activeItem } = this.state;
    return (
      <Menu attached="top" stackable className={"settings-menu"}>
        <Menu.Item>
          <Header as={"h2"} className={"primary"}>
            Settings
          </Header>
        </Menu.Item>
        <Menu.Item
          name={"home"}
          as={NavLink}
          to={this.props.match.url}
          active={activeItem === "home"}
          exact
          onClick={this.handleItemClick}
        >
          {" "}
          Dashboard
        </Menu.Item>

        <Menu.Item
          name={"staff"}
          as={NavLink}
          to={this.props.match.url + "/staff"}
          active={activeItem === "staff"}
          exact
          onClick={this.handleItemClick}
        >
          Staff
        </Menu.Item>
      </Menu>
    );
  }
}
