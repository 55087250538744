import React, { Component } from "react";
import { Header, Table, Icon, Button, Modal, Form } from "semantic-ui-react";

import Moment from "react-moment";
export default class NotesList extends Component {
  state = {
    add: false,
  };

  addNote = () => {
    let data = this.state.data;
    this.props.add(data).then(() => {
      this.setState({
        add: false,
        data: {
          message: "",
        },
      });
    });
  };
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  renderAddModal() {
    return (
      <Modal
        open={this.state.add === true}
        onClose={() => this.setState({ add: false })}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header className="modalHeader">Add Note</Header>
        <Modal.Content>
          <Form>
            <Form.Input
              name="message"
              label="Note"
              onChange={this.onChange}
              placeholder=""
              required={true}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => this.setState({ add: false })}>
            Cancel
          </Button>
          <Button positive onClick={this.addNote}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  render() {
    const systems = this.props.notes
      .map((p, i) => {
        return (
          <Table.Row key={i}>
            <Table.Cell>
              <Moment format={"DD-MM-YYYY HH:mm"}>{p.date}</Moment>
            </Table.Cell>
            <Table.Cell>{p.message}</Table.Cell>
            <Table.Cell collapsing>{p.by}</Table.Cell>
          </Table.Row>
        );
      })
      .sort(function (a, b) {
        return a.date < b.date ? 1 : -1;
      });
    return (
      <div>
        {this.renderAddModal()}

        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>
                <Header className="tableHeader">{this.props.title}</Header>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {this.props.allowAdd && (
                  <Button
                    positive
                    icon
                    onClick={() => this.setState({ add: true })}
                  >
                    <Icon name="plus" />
                  </Button>
                )}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={4}>Date</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell>By</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{systems}</Table.Body>
        </Table>
      </div>
    );
  }
}
